import { create } from "zustand";
import {
  getAllProductList,
  getSingleProductInfo,
  productToggleStatus,
  advanceToggleStatus,
  getProductByBusiness
} from "../Services/productService";

const productStore = create((set) => ({
  products: [],
  singleProduct: null,
  msg: null,
  productStatus:[],
  advanceStatus:[],

  fetchAllProducts: async () => {
    try {
      const response = await getAllProductList();
      set(() => ({
        products: response.data, 
        productStatus: response.data.map(product => product.status), 
        advanceStatus: response.data.map(product => product.advance_payment_status), 
      }));
    } catch (err) {
      console.error("Error fetching all products:", err);
      throw err;
    }
  },
  //get product by business ID state
  fetchProductByBusinessId: async (id) => {
    try {
      const response = await getProductByBusiness(id);
      set((state) => ({
        products: response.data,
      }));
    } catch (err) {
      console.error("Error while fetch product", err);
      throw err;
    }
  },

  fetchSingleProduct: async (param) => {
    try {
      const response = await getSingleProductInfo(param);
      set(() => ({
        singleProduct: response.data,
      }));
    } catch (err) {
      console.error("Error fetching single product info:", err);
      throw err;
    }
  },

  toggleProductStatus: async (id) => {
    try {
      // Call the API to toggle status
      await productToggleStatus(id); // Call the API without expecting a new status
      
      // Update the specific product's status in the products array
      set((state) => {
        // Find the product by id
        const productIndex = state.products.findIndex((product) => product.id === id);
        if (productIndex !== -1) {
          // Toggle the status based on current state
          const updatedProduct = { ...state.products[productIndex], status: !state.products[productIndex].status };
          return {
            products: [
              ...state.products.slice(0, productIndex),
              updatedProduct,
              ...state.products.slice(productIndex + 1),
            ],
            productStatus: [
              ...state.productStatus.slice(0, productIndex),
              !state.productStatus[productIndex],
              ...state.productStatus.slice(productIndex + 1),
            ],
          };
        }
        return state; // No change if product not found
      });
    } catch (err) {
      console.error("Error toggling product status:", err);
      throw err;
    }
  },
  

  
  toggleAdvancePayStatus: async (id) => {
    try {
      // Call the API to toggle advance payment status
      const response = await advanceToggleStatus(id);
      const currentAdvanceStatus = response.data.advance_payment_status; // Assuming this is a boolean
  
      // Update the specific product's advance payment status in the products array
      set((state) => {
        const updatedAdvanceStatus = !currentAdvanceStatus; // Toggle the advance payment status
        return {
          products: state.products.map((product) =>
            product.id === id ? { ...product, advance_payment_status: updatedAdvanceStatus } : product
          ),
          advanceStatus: state.advanceStatus.map((status, index) =>
            state.products[index].id === id ? updatedAdvanceStatus : status
          ),
          msg: response.message,
        };
      });
    } catch (err) {
      console.error("Error toggling advance pay status:", err);
      throw err;
    }
  },

  fetchProductsByBusiness: async (param) => {
    try {
      const response = await getProductByBusiness(param);
      set(() => ({
        products: response.data,
      }));
    } catch (err) {
      console.error("Error fetching products by business:", err);
      throw err;
    }
  },
}));

export default productStore;
