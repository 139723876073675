import React, { Fragment, useState, useEffect } from 'react';
import { Btn, Image } from '../../../../AbstractElements';
import { Modal, Col } from 'reactstrap';

const ProductModal2 = (props) => {
  const [open, setOpen] = useState(props.value);
  const [singleProduct, setSingleProduct] = useState({});

  useEffect(() => {
    // Update modal open state when props.value changes
    setOpen(props.value);

    // Find the single product in bannerData using props.dataid
    const product = props.bannerData.find(item => item.id === props.dataid);
    setSingleProduct(product || {});
  }, [props.value, props.dataid, props.bannerData]);

  const onCloseModal = () => {
    setOpen(false); // Close the modal
    props.setOpenModal(false); // Update the parent component's state
  };

  return (
    <Fragment>
      <Modal
        className='modal-dialog modal-lg modal-dialog-centered product-modal'
        isOpen={open}
        toggle={onCloseModal}
      >
        <div className='modal-body'>
          <div className='modal-header'>
            <div className='modal-title'>
              <div className='product-box row'>
                <Col lg='12'>
                  <Image
                    attrImage={{
                      className: 'img-fluid',
                      src: singleProduct?.ad_image || '', // Use optional chaining to access ad_image
                      alt: singleProduct?.ad_image ? 'Product Image' : '', // Set alt text only if image exists
                    }}
                  />
                </Col>
              </div>
            </div>
            <Btn
              attrBtn={{
                color: 'transparent',
                className: 'btn-close',
                onClick: onCloseModal, // Close the modal when the button is clicked
                type: 'button',
                'data-bs-dismiss': 'modal',
                'aria-label': 'Close',
              }}
            ></Btn>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProductModal2;
