import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';
import OrderHistoryTableCard from './OrderHistoryTableCard';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import shippingFinanceStore from '../../../store/shippingFinanceStore';

const SellerFinance = () => {
  const { summary } = shippingFinanceStore(); // Use the hook to get the store data

  const widgetData1 = {
    title: 'Shipping Charge',
    gros: 50,
    total: `৳${summary?.total_shipping_charge || 0}`, // Use template literals correctly
    color: 'secondary',
    icon: 'cart',
  };

  const widgetData2 = {
    title: 'Total Receive Amount',
    gros: 70,
    total: `৳${summary?.received_amount || 0}`, // Use template literals correctly
    color: 'warning',
    icon: 'return-box',
  };

  const widgetData3 = {
    title: 'Pending Withdraw Request',
    gros: 70,
    total: `৳${summary?.pending_withdraw_request || 0}`, // Use template literals correctly
    color: 'primary',
    icon: 'tag',
  };

  const widgetData4 = {
    title: 'Current Shipping Balance',
    gros: 70,
    total: `৳${summary?.current_shipping_balance || 0}`, // Use template literals correctly
    color: 'primary',
    icon: 'rate',
  };

  return (
    <Fragment>
      <Breadcrumbs parent='Window' title='Seller Finance' mainTitle='seller finance' />
      <Container fluid={true} className='orderhistory'>
        <Row>
          <Col xl='3'>
            <Widgets1 data={widgetData1} />
          </Col>
          <Col xl='3'>
            <Widgets1 data={widgetData2} />
          </Col>
          <Col xl='3'>
            <Widgets1 data={widgetData3} />
          </Col>
          <Col xl='3'>
            <Widgets1 data={widgetData4} />
          </Col>
        </Row>
        <OrderHistoryTableCard />
      </Container>
    </Fragment>
  );
};

export default SellerFinance;
