import React from 'react';
 import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useStore } from 'zustand';
import categoryStore from '../../../../../store/categoryStore';
// import { useEffect } from 'react';

const EditCategoryModal = () => {
  const { modal, updateEditCategoryField, updateCategoryData, closeEditModal, editCategoryList,allCategoryList } = useStore(categoryStore);
  // useEffect(() => {
  //   fetchAllCategory();
  //   // console.log("category:", fetchAllCategory);
  // }, [fetchAllCategory]);
  return (
    <Modal isOpen={modal} toggle={closeEditModal} centered>
      <ModalHeader toggle={closeEditModal}>Edit Category</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="category-name">
              {"Category Name:"}
            </Label>
            <Input
              onChange={updateEditCategoryField}
              className="form-control"
              type="text"
              id="category-name"
              name="name"
              value={updateCategoryData.name}
            />
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label" for="icon">
              {"Icon:"}
            </Label>
            <Input
              onChange={updateEditCategoryField}
              type="text"
              className="form-control"
              id="icon"
              name="icon"
              value={updateCategoryData.icon}
            />
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label" for="upload-image">
              {"Upload Image:"}
            </Label>
            <Input
              onChange={updateEditCategoryField}
              type="file"
              className="form-control"
              id="upload-image"
              name="image"
            />
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label" for="description">
              {"Description:"}
            </Label>
            <Input
              onChange={updateEditCategoryField}
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={updateCategoryData.description}
            />
          </FormGroup>
          <FormGroup>
            <Label className="col-form-label" for="parent-category">
              {"Parent Category:"}
            </Label>
            <Input
              type="select"
              onChange={updateEditCategoryField}
              className="form-control"
              id="parent-category"
              name="parent"
              value={updateCategoryData.parent}
            >
              <option selected>No Parent Category</option>
              {allCategoryList && allCategoryList.length > 0 ? (
              allCategoryList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))
            ) : (
              <option  disabled>
                No categories available
              </option>
            )}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={editCategoryList}>Save</Button>
        <Button color="secondary" onClick={closeEditModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditCategoryModal;
