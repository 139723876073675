import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useState } from 'react';
import DataTableComponent from './DataTableComponent';
import MakeDealForm from './MakeDealForm';
import { Breadcrumbs } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';


const ProductDealsPage = () => {
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Table" title="Offered Product" mainTitle="Offered Product" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <MakeDealForm />
              <HeaderCard title="All Offered Product List" />
              <CardBody>
                <DataTableComponent />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};


export default ProductDealsPage;