import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import CouponForm from './CouponForm';

const NewCouponPage = () => {

  return (
    <Fragment>
      <Breadcrumbs parent='Coupon' title='Coupon Create' mainTitle='Coupon Create' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardBody>
                  <CouponForm />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewCouponPage;