import React, { Fragment, useEffect, useState } from 'react';
import { Name, Save, Cancel } from '../../../../../Constant';
import { Btn } from '../../../../../AbstractElements';
import { Users } from 'react-feather';
import { Row, Col, Modal, ModalHeader, ModalBody, Label, FormGroup, Form } from 'reactstrap';
import { useStore } from 'zustand';
import regionStore from '../../../../../store/regionStore';

const CreateContact = () => {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', country: '' });
  const [formErrors, setFormErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');

  const toggle = () => setModal(!modal);

  const {
    createArea,
    updateCreateAreaField,
    fetchAllStateList,
    allStateList,
    setAreaID,
  } = useStore(regionStore);

  useEffect(() => {
    fetchAllStateList();
  }, [fetchAllStateList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    updateCreateAreaField(e);
  };

  const handleSelect = (e) => {
    const selectedCountryId = Number(e.target.value);
    setAreaID({ id: selectedCountryId });
    const selectedCountryName = allStateList?.find(country => country.id === selectedCountryId)?.name || '';
    setSelectedCountry(selectedCountryName);
    setFormData((prevData) => ({
      ...prevData,
      country: selectedCountryId,
    }));
    updateCreateAreaField({ target: { name: 'state', value: selectedCountryId } });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'State name is required';
    if (!formData.country) errors.country = 'Country is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const AddContact = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await createArea();
      setFormData({ name: '', country: '' });
      setModal(false);
    }
  };

  return (
    <Fragment>
      <Btn
  attrBtn={{
    className: 'badge-light-primary align-items-center btn-mail d-flex justify-content-start w-100 emptyContact',
    color: '',
    onClick: toggle,
  }}
>
  <Users className='me-2' />
  <span >Create Area</span>
</Btn>
      <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle}>Add Area</ModalHeader>
        <ModalBody>
          <Form className='form-bookmark needs-validation' onSubmit={AddContact}>
            <FormGroup className='col-md-12'>
              <Label>{Name}</Label>
              <Row>
                <Col sm='6'>
                  <input
                    className='form-control'
                    name='name'
                    type='text'
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className='col-md-12'>
              <Label>State</Label>
              <Row>
                <Col sm='6'>
                  <select
                    className='form-control'
                    name='country'
                    value={formData.country}
                    onChange={handleSelect}
                  >
                    <option value=''>Select State</option>
                    {allStateList?.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {formErrors.country && <span style={{ color: 'red' }}>{formErrors.country}</span>}
                </Col>
              </Row>
            </FormGroup>
            <Btn attrBtn={{ color: 'secondary', type: 'submit', className: 'me-2' }}>{Save}</Btn>
            <Btn attrBtn={{ color: 'primary', onClick: toggle }}>{Cancel}</Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CreateContact;
