import React, { useState } from "react";
import DataTable from "react-data-table-component";

const Table = ({
    data,
    columns,
    onPageChange,
    onPageSizeChange,
    totalRows,          // Total number of rows (all records, used for pagination)
    pageSize,           // Current page size (how many records per page)
    defaultPageSize = 10,
}) => {
    const [page, setPage] = useState(1);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        if (onPageChange) onPageChange(newPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        if (onPageSizeChange) onPageSizeChange(newPageSize);
    };

    return (
        <DataTable
            data={data}
            columns={columns}
            striped
            center
            pagination
            paginationServer              // Ensures external pagination logic
            paginationTotalRows={totalRows} // Total number of rows in the dataset
            paginationDefaultPage={page}   // Current page
            paginationPerPage={pageSize}   // Rows per page (controlled by state)
            paginationRowsPerPageOptions={[5, 10, 20, 50]} // Page size options
            onChangePage={handlePageChange} // Handles page changes
            onChangeRowsPerPage={handlePageSizeChange} // Handles page size changes
        />
    );
};

export default Table;
