import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import CommonModal from "../../../../../UiKits/Modals/common/modal";
import { useStore } from "zustand";
import businessTypeStore from "../../../../../../store/businessTypeStore";

const FormModal = ({ modal, NewMessage, toggle, defaultVal }) => {
  const { createBusinessType,updateBusinessTypeField,createNewBusinessType,fetchAllBusinessType } =
    useStore(businessTypeStore);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await createBusinessType();
      await fetchAllBusinessType()
      toggle();
    } catch (err) {
      console.error("Error while create Banner", err);
    }
  };
  return (
    <CommonModal
      isOpen={modal}
      title={"New Category"}
      toggler={toggle}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormGroup>
          <Label className="col-form-label" for="banner_url">
            {"Business Type Name:"}
          </Label>
          <Input
            onChange={updateBusinessTypeField}
            className="form-control"
            type="text"
            id="banner_url"
            name="business_type"
            value={createNewBusinessType.business_type}
          />
        </FormGroup>
      </Form>
    </CommonModal>
  );
};

export default FormModal;
