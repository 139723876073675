import React, { useEffect, useState } from "react";
import './toggleButton.css'; // Import custom styles

const ToggleBtn = ({ value, onToggle }) => {
  const [isActive, setIsActive] = useState(value);

  useEffect(() => {
    setIsActive(value);
  }, [value]);

  const handleToggle = () => {
    onToggle();
    setIsActive(!isActive);
  };

  return (
    <div className="toggle-switch" onClick={handleToggle}>
      <div className={`switch ${isActive ? 'active' : 'inactive'}`}>
        <div className="switch-handle"></div>
      </div>
      <span className="switch-label">
        {isActive ? 'active' : 'Inactive'}
      </span>
    </div>
  );
};

export default ToggleBtn;
