import { Form, CardHeader, Media, Card } from "reactstrap";
import { Fragment } from "react";
const SearchBar = ({ onSearch }) => {
  const handleChange = (e) => {
    onSearch(e.target.value);
  };

  return (
    <Fragment>
      <div className="file-content">
        <Card>
          <CardHeader>
            <Media>
              <Form className="search-file form-inline">
                <div className="mb-0 form-group">
                  <i className="fa fa-search"></i>
                  {/* value={searchTerm} */}
                  <input
                    className="form-control-plaintext"
                    type="text"
                    onChange={handleChange}
                    placeholder="Search..."
                  />
                </div>
              </Form>
            </Media>
          </CardHeader>
        </Card>
      </div>
    </Fragment>
  );
};

export default SearchBar;
