import apiServices from "./apiServices";
import apiEndpoints from "./apiEndpoints";
//handle login login 
export const login = async(data) =>
{
    try{
        //get response
        const response = await apiServices.post(apiEndpoints.login,data);
        //save token
        // apiServices.setToken(response.data.access_token)
        return response.data;
    } catch(error)
    {
        console.log("Login Error:",error);
        throw error
    }
};

export default {
    login,
}