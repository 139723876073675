import { icon } from "leaflet";


export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      { path: `/dashboard/default`, icon: "home", title: "DashBoard", type: "link" },
      { path: `/app/customer`, icon: "user", title: "Customer", type: "link" },
      { path: `/app/business`, icon: "task", title: "Onboard Business", type: "link" },
    ],
  },

  {
    menutitle: "Applications",
    menucontent: "Ready to use Apps",
    Items: [
      {
        title: "Coupon",
        icon: "project",
        type: "sub",
        badge: "badge badge-light-secondary",
        badgetxt: "offer",
        active: false,
        children: [
          { path: `/app/coupon/coupon-list`, type: "link", title: "Coupon List" },
          { path: `/app/coupon/new-coupon`, type: "link", title: "Create New Coupon" },
        ],
      },
      {
        title: "Deals of The Day",
        icon: "ecommerce",
        type: "sub",
        badge: "badge badge-light-secondary",
        badgetxt: "!",
        active: true,
        children: [
          { path: `/app/deal-of-the-day/deals-product/`, type: "link", title: "Deals Products" },
          { path: `/app/deal-of-the-day/deals-setting/`, type: "link", title: "Deals Setting" },
        ],
      },
      //seller Order
      { path: `/app/seller-finance`, icon: 'charts', title: "Seller Finance", type: "link" },
      //Payment Method
      { path: `/app/payment-method`, icon: 'blog', title: "Payment Method", type: "link" },

      {
        title: "Order Panel",
        icon: "calendar",
        type: "sub",
        badge: "badge badge-light-secondary",
        badgetxt: "selling",
        active: true,
        children: [
          { path: `/app/seller/seller-order-list/`, type: "link", title: "Seller Order" },
          { path: `/app/seller/seller-order-list/order-filter`, type: "link", title: "Filter Order List" },
        ],
      },
      //WithDrawal panel
      { path: `/app/withdrawal`, icon: "to-do", title: "WithDrawal", type: "link" },
      //Seller Shipping finance
      { path: `/app/seller-shipping-finance`, icon: "task", title: "Seller Shipping Finance", type: "link" },
      { path: `/app/shipping-withdraw`, icon: "button", title: "Shipping Withdraw", type: "link" },
      {
        title: "Payment Gateway ",
        icon: "calendar",
        type: "sub",
        active: true,
        children: [
          { path: `/app/payment-gateway/logs`, type: "link", title: "PG Logs" },
          { path: `/app/payment-gateway/history`, type: "link", title: "PG History" },
        ],
      },


      {
        title: "Ecommerce",
        icon: "ecommerce",
        type: "sub",
        active: false,
        children: [
          { path: `/app/ecommerce/product`, title: "Products", type: "link" },
          // { path: `/app/ecommerce/product-page/1`, title: "Product-Page", type: "link" },
          { path: `/app/ecommerce/product-category`, title: "Product-Category", type: "link" },
          { path: `/app/ecommerce/banner`, title: "Home Slider", type: "link" },
          { path: `/app/home-ads`, title: "Home Ads", type: "link" },
          { path: `/app/ecommerce/business-type`, title: "Business Type", type: "link" },
          { path: `/app/ecommerce/payment-details`, title: "Payment-Detail", type: "link" },
          { path: `/app/ecommerce/orderhistory`, title: "OrderHistory", type: "link" },
          { path: `/app/ecommerce/invoice`, title: "Invoice", type: "link" },
          {
            title: "Region",
            icon: "social",
            type: "sub",
            active: false,
            children: [
              { path: `/app/ecommerce/country/`, title: "Country", type: "link" },

              { path: `/app/ecommerce/state/`, title: "State", type: "link" },
              { path: `/app/ecommerce/area/`, title: "Area", type: "link" },

            ],
          }
        ],
      },
      {
        title: "Users",
        icon: "user",
        path: `/app/users/profile`,
        type: "sub",
        bookmark: true,
        active: false,
        children: [
          { path: `/app/users/profile`, type: "link", title: "User Profile" },
          // { path: `/app/users/edit`, type: "link", title: "User Edit" },
          // { path: `/app/users/cards`, type: "link", title: "User Cards" },
        ],
      },
    ],
  },
];
