
import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllOffers = async() =>
{
    try{
        
        const response = await apiServices.get(apiEndpoints.getAllOffer);

        return response.data
    }catch(err)
    {
        console.error("Fetching error in all offer services:",err);
        throw err
    }
}

export const createOffer = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.createOffer,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create offer:",err)
        throw err
    }
}

export const deleteOffer = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.deleteOffer+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a offer:",err);
        throw err
    }
}

export const toggleOfferStatus = async(param)=>
{
    try{
        const response = await apiServices.get(apiEndpoints.toggleStatusOffer+param);
        return response.data
    }catch(err)
    {
        console.error("Error while toggling offer status:",err)
        throw err;
    }
}
//deals setting
export const updateOfferSetting = async(data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.updateOfferSetting,data)
        return response.data
    }catch(err)
    {
        console.error("Error while updating offer:",err);
        throw err
    }
}

export const getOfferSetting = async()=>
{
    try{
        const response = await apiServices.get(apiEndpoints.getOfferSettings)
        return response.data;
    }catch(err)
    {
        console.log("error while getting offer Setting:",err)
        throw err
    }
}


export default {
    getAllOffers,
    createOffer,
    deleteOffer,
    updateOfferSetting,
    toggleOfferStatus,
    getOfferSetting,
}
