import React, { Fragment, useEffect, useState } from 'react';
import { Btn, H4, H6, Image, P } from '../../../../AbstractElements';
import { Modal, Col } from 'reactstrap';
import { useStore } from 'zustand';
import productStore from '../../../../store/productStore'; // Adjust the path if needed

const ProductModal = (props) => {
  const [open, setOpen] = useState(props.value);
  const { fetchSingleProduct, singleProduct } = useStore(productStore); // Fetch from Zustand store
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (props.dataid) {
      fetchSingleProduct(props.dataid); // Fetch single product details when the modal opens
    }
  }, [props.dataid, fetchSingleProduct]);

  // Ensure singleProduct is the first element in the array if it's an array
  const product = Array.isArray(singleProduct) ? singleProduct[0] : singleProduct;

  const plusQty = () => setQuantity((prev) => prev + 1);
  const minusQty = () => setQuantity((prev) => (prev > 1 ? prev - 1 : prev));
  const onCloseModal = () => {
    setOpen(false);
    props.setOpenModal(false);
  };

  return (
    <Fragment>
      <Modal className='modal-lg modal-dialog-centered product-modal' isOpen={open} toggle={onCloseModal}>
        <div className='modal-body'>
          <div className='modal-header'>
            <h5 className='modal-title'>{product?.name || 'Product Details'}</h5>
            <Btn attrBtn={{ color: 'transparent', className: 'btn-close', onClick: onCloseModal }}></Btn>
          </div>
          <div className='product-box row'>
            <Col lg='6'>
              <Image
                attrImage={{
                  className: 'img-fluid',
                  src: product?.image1 || 'default_image.jpg', // Fallback image if not available
                  alt: product?.name || 'Product Image',
                }}
              />
            </Col>
            <Col lg='6' className='product-details text-start'>
              <H4>{product?.name || 'Product Name'}</H4>
              <div className='product-price'>
                ৳{product?.sale_price || 0}
                <del>
                  ৳{product?.regular_price || 0}
                </del>
              </div>
              <div className='product-view'>
                <H6 attrH6={{ className: 'f-w-600' }}>{product?.short_description || 'No description available'}</H6>
                <P attrPara={{ className: 'mb-0' }}>{product?.description || 'No additional details available.'}</P>
              </div>
              <div className='product-qnty'>
                <H6 attrH6={{ className: 'f-w-600' }}>Stock: {product?.stock_quantity || 'N/A'}</H6>
                <H6 attrH6={{ className: 'f-w-600' }}>Rating: {product?.average_rating || 0} out of 5</H6>
              </div>
              
            </Col>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProductModal;
