import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { tableColumns } from "./CustomerData";
import { BusinessList } from "./CustomerData";
import { useStore } from "zustand";
import paymentMethodStore from "../../../store/paymentMethodStore";
import Modal from "./modal";
import "./Modal.css";

const DataTableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingMethod, setEditingMethod] = useState(null);

  const {
    createNewMethod,
    updateCreateMethodField,
    createMethod,
    deleteMethod,
    fetchAllMethod,
    editMethod
  } = useStore(paymentMethodStore);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (editingMethod) {
      await editMethod(editingMethod.id, createNewMethod);
    } else {
      await createMethod();
    }
    setIsModalOpen(false);
    setEditingMethod(null); // Reset editingMethod state
    fetchAllMethod();
  };

  useEffect(() => {
    fetchAllMethod();
  }, [fetchAllMethod]);

  const openCreateModal = () => {
    setEditingMethod(null); // Ensure editingMethod is reset
    setIsModalOpen(true);
  };

  const openEditModal = (business) => {
    setEditingMethod(business);
    setIsModalOpen(true);
  };

  return (
    <Fragment>
      <button
        onClick={openCreateModal}
        style={{
          backgroundColor: "#4CAF50",
          color: "white",
          padding: "15px 32px",
          fontSize: "16px",
          borderRadius: "12px",
          border: "none",
          cursor: "pointer",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#4CAF50")}
      >
        Create Payment Method
      </button>

      {/* Modal for Creating or Editing Payment Method */}
      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <h2 className="modal-title">
            {editingMethod ? 'Edit Payment Method' : 'Create Payment Method'}
          </h2>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="logo">Payment method Logo:</label>
              <input
                type="file"
                name="logo"
                id="logo"
                onChange={updateCreateMethodField}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                name="name"
                id="name"
                value={createNewMethod.name}
                onChange={updateCreateMethodField}
                placeholder="Enter payment method name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="charge">Charge:</label>
              <input
                type="text"
                name="charge"
                id="charge"
                value={createNewMethod.charge}
                onChange={updateCreateMethodField}
                placeholder="Enter charge amount"
              />
            </div>
            <button type="submit">
              {editingMethod ? 'Update' : 'Submit'}
            </button>
          </form>
        </Modal>
      )}

      <BusinessList setData={setData} openEditModal={openEditModal} />
      <DataTable
        data={data}
        columns={tableColumns}
        striped
        center
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelete}
      />
    </Fragment>
  );
};

export default DataTableComponent;
