import React, { Fragment, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Close, SaveChanges } from '../../../Constant';
import { Btn } from '../../../AbstractElements';

const ReusableModal = ({
    modalBtnText,
    modalTitle,
    modalContent,
    onSubmit,
}) => {
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const saveChanges = () => {
        onSubmit({ closeModal: setModal(false) });
    }
    return (
        <Fragment>
            <Btn attrBtn={{ color: 'primary', onClick: () => setModal(true), style:{width:"max-content",margin:"10px"} }} width>{modalBtnText}</Btn>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{modalTitle ?? "Title"}</ModalHeader>
                <ModalBody>
                    {modalContent}
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{ color: 'secondary', onClick: toggleModal }} >{Close}</Btn>
                    <Btn attrBtn={{ color: 'primary', onClick: saveChanges }}>{SaveChanges}</Btn>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default ReusableModal;