import React, { Fragment, useState, useEffect } from 'react';
import { Btn } from '../../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { Row, Col, Label, FormGroup, Form } from 'reactstrap';
import { Name, Save, Cancel } from '../../../../../Constant';
import { useStore } from 'zustand';
import regionStore from '../../../../../store/regionStore';

const UpdateUser = ({ editdata, userEditCallback, editurl }) => {
    const {  editState, StateId,fetchAllStateList } = useStore(regionStore);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [editUrl, setEditurl] = useState(editurl);

    useEffect(() => {
        if (editdata) {
            setValue('name', editdata.name);
            fetchAllStateList()
        }
    }, [editdata, setValue]);

    const UpdateContact = async (data) => {
        if (data) {
            await editState(StateId, data); // Pass id as parameter
            userEditCallback(false, data, editUrl, true);
        } else {
            errors.showMessages();
        }
    };

    return (
        <Fragment>
            <div className="contact-editform ps-0 m-auto">
                <Form onSubmit={handleSubmit(UpdateContact)}>
                    <div className="form-row">
                        <FormGroup className="col-md-12">
                            <Label>{Name}</Label>
                            <Row>
                                <Col sm="6">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        defaultValue={editdata.name}
                                        {...register('name', { required: true })}
                                    />
                                    {errors.name && <span style={{ color: 'red' }}>Name is required</span>}
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                    <Btn attrBtn={{ color: 'secondary', className: 'update-contact me-1' }}>{Save}</Btn>
                    <Btn attrBtn={{ color: 'primary', onClick: () => userEditCallback(false) }}>{Cancel}</Btn>
                </Form>
            </div>
        </Fragment>
    );
};

export default UpdateUser;
