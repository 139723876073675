import { create } from "zustand";
import {
  allBusinessType,
  createBusinessType,
  deleteBusinessType,
  editBusinessType,
} from "../Services/businessTypeService";

const BusinessTypeStore = create((set) => ({
  createNewBusinessType: {
    business_type: "",
  },
  id: null,
  updateBusinessType: {
    business_type: "",
  },

  allBusinessTypeList: [],
  modal: false,

  // Create a new category
  createBusinessType: async () => {
    try {
      const { createNewBusinessType } = BusinessTypeStore.getState();
      const response = await createBusinessType(createNewBusinessType);
      set((state) => ({
        allBusinessTypeList: [...state.allBusinessTypeList, response.data],
        createNewBusinessType: {
          business_type: "",
        },
      }));
    } catch (error) {
      console.log("Error while creating Business Type:", error);
      throw error;
    }
  },

  updateBusinessTypeField: (e) => {
    const { name, value } = e.target;

    set((state) => ({
      createNewBusinessType: {
        ...state.createNewBusinessType,
        [name]: value,
      },
    }));
  },

  fetchAllBusinessType: async () => {
    try {
      const response = await allBusinessType();
      set((state) => ({
        allBusinessTypeList: response.data,
      }));
    } catch (err) {
      console.error("Error fetching Business Type list:", err);
    }
  },

  // Toggle edit category modal and set category data
  toggleEditType: (type) => {
    console.log(type);
    set(() => ({
      updateBusinessType: { business_type: type.business_type },
      id: type.id,
      modal: true,
    }));
  },

  // Close the edit modal
  closeEditModal: () => {
    set(() => ({
      modal: false,
      updateBusinessType: { business_type: "" },
      id: null,
    }));
  },

  updateEditBusinessTypeField: (e) => {
    const { name, value } = e.target;
    set((state) => ({
      updateBusinessType: {
        ...state.updateBusinessType,
        [name]: value,
      },
    }));
  },

  editBusinessTypeList: async () => {
    try {
      const { updateBusinessType, allBusinessTypeList, id } =
        BusinessTypeStore.getState();
      const response = await editBusinessType(id, updateBusinessType);
      const newCategory = [...allBusinessTypeList];
      const categoryIndex = allBusinessTypeList.findIndex((data) => {
        return data.id === id;
      });
      newCategory[categoryIndex] = response.data;
      set((state) => ({
        allBusinessTypeList: newCategory,
        updateBusinessType: {
          business_type: "",
        },
        id: null,
        modal: false,
      }));
    } catch (err) {
      console.error("Error while editing Business Type:", err);
      throw err;
    }
  },

  deleteType: async (id) => {
    try {
      await deleteBusinessType(id);
      set((state) => ({
        allBusinessType: state.allBusinessTypeList.filter(
          (category) => category.id !== id
        ),
      }));
    } catch (err) {
      console.error("Error while deleting business type:", err);
    }
  },
}));

export default BusinessTypeStore;
