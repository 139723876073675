import { Views, FollowUp, Orgenization, Favourites, Ideas, Business, Holidays, Important, Personal } from '../../../../../Constant';
import CreateContact from './CreateContact';
import { Nav, NavItem } from 'reactstrap';
import React, { Fragment, useState } from 'react';

const NavComponent = ({ callbackActive }) => {
    const [activeTab, setActiveTab] = useState('1');

    return (
        <Fragment>
            <Nav className="main-menu contact-options" role="tablist">
                <NavItem>
                    <CreateContact />
                </NavItem>
            </Nav>
        </Fragment>
    );
};

export default NavComponent;