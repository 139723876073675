import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllPaymentMethodList = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getPaymentMethodInfo)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in payment method list:",err);
        throw err
    }
}



export const paymentMethodToggleStatus = async(id)=>
{
    try{
        
        const response  = await apiServices.post(apiEndpoints.updatePaymentInfoStatus+id);
        return response.data
    }catch(err)
    {
        console.error("failed toggle status",err)
    }
}

export const createPaymentMethodType = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.createPaymentMethod,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create Payment Method:",err)
        throw err
    }
}

export const editPaymentMethod = async(param,data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.updatePaymentMethod+param,data)
        return response.data
    }catch(err)
    {
        console.error("Error while editing Payment Method type:",err);
        throw err
    }
}

export const deletePaymentMethod = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.deletePaymentMethod+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a payment Method:",err);
        throw err
    }
}




export default {getAllPaymentMethodList,paymentMethodToggleStatus,createPaymentMethodType,editPaymentMethod,deletePaymentMethod}