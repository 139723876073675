import React, { useState } from 'react';
import { Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import HeaderCard from '../../../../../Components/Common/Component/HeaderCard';
import ModalButton from './common/ModalButton';

const VaryingContent = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const toggleModal = () => setModal(!modal);

  const handleButtonClick = (content) => {
    setModalContent(content);
    toggleModal();
  };

  return (
    <Col sm="12">
      <Card>
        <HeaderCard title={"Create New Business Type"} />
        <CardBody>
          <div className="btn-showcase">
            <ModalButton btnText='Open modal for @mdo' onClick={() => handleButtonClick('@mdo')} />
          </div>
        </CardBody>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Modal Title</ModalHeader>
        <ModalBody>
          {modalContent}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

export default VaryingContent;
