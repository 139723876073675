import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import CommonModal from "../../../../../UiKits/Modals/common/modal";
import { useStore } from "zustand";
import useAdStore from "../../../../../../store/adStore";

const FormModal = ({ modal, NewMessage, toggle, defaultVal }) => {
  const { updateAdSection2 } = useStore(useAdStore);
  const store = useAdStore();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await updateAdSection2();
      toggle();
    } catch (err) {
      console.error("Error while creating ads 2", err);
    }
  };

  return (
    <CommonModal
      isOpen={modal}
      title={"Update Ads 2"}
      toggler={toggle}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormGroup>
          <Label className="col-form-label" for="ad_type">
            {"Select Ads"}
          </Label>
          <Input
            type="select"
            id="ad_type"
            name="adNumber"
            className="form-control"
            value={store.adNumber || ""}
            onChange={store.updateCreateAds1Field}
          >
            <option value="1">Ads 1</option>
            <option value="2">Ads 2</option>
            <option value="3">Ads 3</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label" for="banner_url">
            {"Ads URL:"}
          </Label>
          <Input
            onChange={store.updateCreateAds1Field}
            className="form-control"
            type="text"
            id="banner_url"
            name="ad_url"
            value={store.createAd1.ad_url || ""}
          />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label" for="banner_image">
            {"Upload Ads Image:"}
          </Label>
          <Input
            onChange={store.updateCreateAds1Field}
            type="file"
            className="form-control"
            id="banner_image"
            name="ad_image"
          />
        </FormGroup>
      </Form>
    </CommonModal>
  );
};

export default FormModal;
