import React from 'react';

const ProductRating = ({ rating }) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < Math.ceil(rating); i++) {
      stars.push(<i key={i} className="fa fa-star font-warning"></i>);
    }
    return stars;
  };

  return <div className="rating">{renderStars()}</div>;
};

export default ProductRating;
