export default {
    login : '/api/token',
    accessTokenKey:'access_token',
    refreshToken:'refresh_token',
    allCategory:'/api/product/category/read',
    create_category: '/api/product/category/create',
    delete_category: '/api/product/category/delete/',
    edit_category: '/api/product/category/update/',
    add_banner: '/api/home-slider/add',
    get_all_banner:'/api/home-slider',
    delete_banner:'/api/home-slider/delete/',
    getCustomerList:'/api/customer-list',
    //onBoarded business List
    getBusiness: '/api/business/read',
    toggleStatus:'/api/business/status/toggle/',
    toggleFeature:'/api/business/featured/toggle/',
    toggleCommission:'/api/business/commission/toggle/',
    //business type
    create_business_type:'/api/business-types/create',
    getBusinessType:'/api/business-types/read',
    editBusinessType:'/api/business-type/update/',
    deleteBusinessType:'/api/business-type/delete/',
    //offer
    createOffer:'/api/offer/deals/create',
    getAllOffer:'/api/offer/deals/read',
    toggleStatusOffer:'/api/offer/deals/status/',
    deleteOffer:'/api/offer/deals/delete/',
    //offer Setting
    getOfferSettings:'/api/offer/deals/settings',
    updateOfferSetting:'/api/offer/deals/settings/update',
    //get product by business
    getProductByBusiness:'/api/product/business/',
    //get all business name
    getProductBusinessSimple:'/api/business/read/simple',
    //seller Order
    getOrderList:"/api/order/order-list",
    getShopOrderById: '/api/order/shop-order/',
    getShopFinanceById: '/api/finance/finance',
    //seller Order Invoice
    getSellerOrderInvoice:'/api/order/order-details/',
    //order status changing
    editStatusOrder:'/api/order/status/',
    //Order Filtering
    filterOrder:'/api/order/filter',
    //withdrawal request
    withdrawalRequest: '/api/finance/withdraw-requests',
    //withdrawal request using shop
    withdrawalRequestWithShop: '/api/finance/withdraw-requests/shop/',
    //Seller Shipping Finance 
    sellerShippingFinance:'/api/finance/shipping-finance',
    sellerShippingFinanceByShop:'/api/finance/shipping-finance/',
    //seller Shipping Withdraw
    sellerShippingWithdraw:'/api/finance/shipping-withdraw-requests',
    sellerShippingWithdrawByShop:'/api/finance/shipping-withdraw-requests/shop/',
    //update Withdraw Request
    updateStatusOfShippingWithdraw:'/api/finance/shipping-withdraw-requests/status/',

    //Pg Logs
    fetchPgLogs:'/api/payment/pgw/log',
    //Pg History
    fetchPgHistory:'/api/payment/pgw/history',
    //region Country
    createCountry: '/api/country/create',
    fetchAllCountry:'/api/country/read',
    fetchCountryInfo:'/api/country/read/',
    updateCountryInfo:'/api/country/update/',
    deleteCountry:'/api/country/delete/',
    //region state
    createState:'/api/state/create',
    fetchAllState:'/api/state/read',
    fetchSingleState:'/api/state/read/',
    fetchStateByCountry:'/api/state/read/country/',
    updateStateInfo:'/api/state/update/',
    deleteState:'/api/state/delete/',
    //region Area
    createArea:'/api/area/create',
    fetchAllArea:'/api/area/read',
    fetchSingleArea:'/api/area/read/',
    fetchAreaByState:'/api/area/read/country/',
    updateAreaInfo:'/api/area/update/',
    deleteArea:'/api/area/delete/',
    //payment method
    getPaymentMethodInfo:'/api/payment',
    createPaymentMethod:'/api/payment/create',
    updatePaymentMethod:'/api/payment/update/',
    updatePaymentInfoStatus:'/api/payment/status/',
    deletePaymentMethod:'/api/payment/delete/',
    //user Info
    getUserInfo:'/api/user/info',
    updateUserInfo:'/api/user/info/update',

    //Products
    getAllProduct:'/api/product',
    getAllProductSimple:'/api/product/simple',
    getSingleProductInfo:'/api/product/',
    getProductByBusiness:'/api/product/business/',
    ProductStatusToggle:'/api/product/status/toggle/',
    productAdvancePayToggle:'/api/product/advance-payment/toggle/',
    deleteAllProduct:'/api/product/delete-all',

    getCouponList:'/api/coupon',
    getCouponInfo:'/api/coupon/',
    CreateCoupon:"/api/coupon/create",
    UpdateCoupon:"/api/coupon/update/",
    updateCouponStatus:"/api/coupon/status/",
    // validateCouponCode:"",
    deleteCoupon:"/api/coupon/delete/",
    //Home Ads
    getAddSection1:'/api/ads/1',
    updateAdSection1:'/api/ads/1/update/',
    getAddSection2:'/api/ads/2',
    updateAdSection2:'/api/ads/2/update/',
    getAddSection3:'/api/ads/3',
    updateAdSection3:'/api/ads/3/update/',
}