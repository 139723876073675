
import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllCoupon = async() =>
{
    try{
        
        const response = await apiServices.get(apiEndpoints.getCouponList);

        return response.data
    }catch(err)
    {
        console.error("Fetching error in all offer services:",err);
        throw err
    }
}
export const getCouponInfo = async(param) =>
{
    try{
        
        const response = await apiServices.get(apiEndpoints.getCouponInfo+param);

        return response.data
    }catch(err)
    {
        console.error("Fetching error in get coupon info:",err);
        throw err
    }
}

export const createCoupon = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.CreateCoupon,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create coupon:",err)
        throw err
    }
}

export const deleteCoupon = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.deleteCoupon+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a offer:",err);
        throw err
    }
}

export const toggleCouponStatus = async(param)=>
{
    try{
        const response = await apiServices.post(apiEndpoints.updateCouponStatus+param);
        return response.data
    }catch(err)
    {
        console.error("Error while toggling offer status:",err)
        throw err;
    }
}
//deals setting
export const updateCoupon = async(param,data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.UpdateCoupon+param,data)
        return response.data
    }catch(err)
    {
        console.error("Error while updating coupon:",err);
        throw err
    }
}




export default {
    updateCoupon,
    toggleCouponStatus,
    deleteCoupon,
    createCoupon,
    getCouponInfo,
    getAllCoupon,
}
