import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useState } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import DealSettingForm from './DealSettingForm';


const DealsSettingsPage = () => {
  const [modal, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modal);
    };

  return (
    <Fragment>
      <Breadcrumbs parent="Table" title="Deals Setting" mainTitle="Deals Setting" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DealSettingForm/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default DealsSettingsPage;