import { H6, Image } from '../../../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import React, { Fragment, useState, useEffect } from 'react';
import { useStore } from 'zustand';
import businessStore from '../../../../store/businessStore';
import productStore from '../../../../store/productStore';

const ProductSidebar = () => {
    const { businessList, filteredBusinessList, fetchAllBusiness, filterBusinessList } = useStore(businessStore);
    const { fetchProductByBusinessId } = useStore(productStore);

    const [sidebaron, setSidebaron] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        fetchAllBusiness();
    }, [fetchAllBusiness]);

    const onClickFilter = () => {
        setSidebaron(!sidebaron);
        document.getElementById('product-wrapper').classList.toggle('sidebaron', sidebaron);
    };

    const handleSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
        filterBusinessList(keyword);
    };

    const handleBusinessClick = (businessId) => {
        fetchProductByBusinessId(businessId);
    };

    return (
        <Fragment>
            <Row>
                <Col xl="3" md="6">
                    <div className={`product-sidebar ${sidebaron ? 'open' : ''}`}>
                        <div className="filter-section">
                            <Card>
                                <CardHeader>
                                    <H6 attrH6={{ className: 'mb-0 f-w-600' }}>Business Filters
                                        <span className="pull-right">
                                            <i className="fa fa-chevron-down toggle-data"
                                                onClick={onClickFilter}>
                                            </i>
                                        </span>
                                    </H6>
                                </CardHeader>
                                <div className="left-filter">
                                    <CardBody className="filter-cards-view animate-chk">
                                      
                                        <div className="business-filter mt-2">
                                            <ul>
                                                {filteredBusinessList.map((business) => (
                                                    <li key={business.id} onClick={() => handleBusinessClick(business.id)}>
                                                        {business.business_name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Col>
                <Col xl="9" md="6">
                    <div className="product-search">
                        <Form>
                            <FormGroup className="m-0 form-group search-product">
                                <Input className="form-control" type="text" placeholder="search"
                                    value={searchKeyword}
                                    onChange={(e) => handleSearchKeyword(e.target.value)} />
                                <i className="fa fa-search"></i>
                            </FormGroup>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};
export default ProductSidebar;
