import React from 'react';
 import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useStore } from 'zustand';
import businessTypeStore from '../../../../../../store/businessTypeStore';
// import { useEffect } from 'react';

const EditBusinessTypeModal = () => {
  const { modal, updateBusinessType, updateEditBusinessTypeField, closeEditModal, editBusinessTypeList } = useStore(businessTypeStore);
  // useEffect(() => {
  //   fetchAllCategory();
  //   // console.log("category:", fetchAllCategory);
  // }, [fetchAllCategory]);

  const handleEditBusinessChange = (e)=>
  {
    updateEditBusinessTypeField(e)
  }
  return (
    <Modal isOpen={modal} toggle={closeEditModal} centered>
      <ModalHeader toggle={closeEditModal}>Edit Business Type</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="category-name">
              {"Business Type Name:"}
            </Label>
            <Input
              onChange={handleEditBusinessChange}
              className="form-control"
              type="text"
              id="business_type"
              name="business_type"
              value={updateBusinessType.business_type }
            />
          </FormGroup>
         
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={editBusinessTypeList}>Save</Button>
        <Button color="secondary" onClick={closeEditModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditBusinessTypeModal;
