import React, { Fragment, useCallback, useContext, useState } from 'react';
import { Name, Edit, Delete, General } from '../../../../../Constant';
import { H5, H6, LI, UL } from '../../../../../AbstractElements';
import SearchNotFoundClass from './SearchNotFoundClass';
import PrintModal from './PrintModal';
import SweetAlert from 'sweetalert2';
import { useStore } from 'zustand';
import regionStore from '../../../../../store/regionStore';
import UpdateUser from './UpdateUser'; 

const ContactDetailsClass = ({ userEditCallback, selectedUser, editurl, imgUpdate }) => {
    const [printmodal, setPrintModal] = useState(false);
    const printModalToggle = () => setPrintModal(!printmodal);

    const { setUpdateStateData, editCountry, deleteState, modal, updateStateData, setStateID, stateId } = useStore(regionStore);

    const toggleCallback = useCallback((toggle) => {
        setPrintModal(toggle);
    }, []);

    const handleEditUsers = (usersData) => {
        setUpdateStateData(usersData);
        setStateID(usersData); // Ensure ID is set when edit operation is initiated
        userEditCallback(true, usersData, usersData.avatar);
    };

    const handleDeleteUser = (userId) => {
        console.log("delete:",userId)
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                deleteState(stateId || userId); // Use the stored id or fallback to userId
                SweetAlert.fire('Deleted!', 'Your file has been deleted.', 'success');
            } else {
                SweetAlert.fire('Cancelled', 'Your file is safe!', 'info');
            }
        });
    };

    return (
        <Fragment>
            {selectedUser ? (
                <div className="profile-mail">
                    <div className="media">
                        <div className="media-body mt-0">
                            <H5>
                                <span className="first_name_0">{selectedUser.name}</span>
                            </H5>
                            <UL attrUL={{ className: 'flex-row' }}>
                                <LI><a href="#javaScript" onClick={() => handleEditUsers(selectedUser)}>{Edit}</a></LI>
                                <LI><a href="#javaScript" onClick={() => handleDeleteUser(selectedUser.id)}>{Delete}</a></LI>
                            </UL>
                        </div>
                    </div>
                    <div className="email-general">
                        <H6 attrH6={{ className: 'mb-3' }}>{General}</H6>
                        <UL>
                            <LI attrLI={{ className: 'border-0' }}>{Name} <span className="font-primary first_name_0">{selectedUser.name}</span></LI>
                        </UL>
                    </div>
                </div>
            ) : (
                <SearchNotFoundClass />
            )}
            {selectedUser && (
                <PrintModal toggleCallback={toggleCallback} printmodal={printmodal} selectedUser={selectedUser} />
            )}
            {modal && (
                <UpdateUser 
                    editdata={updateStateData}
                    userEditCallback={userEditCallback}
                    editurl={editurl}
                />
            )}
        </Fragment>
    );
};

export default ContactDetailsClass;
