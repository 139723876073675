import { H4, LI, P, Image, UL } from "../../../../AbstractElements";
import ProductModal from "./ProductModal";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Row, Card, Button } from "reactstrap";
import bannerStore from "../../../../store/bannerStore";
import { useStore } from "zustand";
import { FaTrash } from 'react-icons/fa';
import CustomizerContext from "../../../../_helper/Customizer";

const ProductGrid = () => {
  const {allBannerList,fetchAllBanner, deleteBanner} = useStore(bannerStore)
  // const layoutColumns = 3;
  const { layoutURL } = useContext(CustomizerContext);

  const quantity = 1;
  const [dataid, setDataid] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const history = useNavigate();


  //load all banner
  useEffect(()=>
{
  const fetchBanners = async()=>
  {
    await fetchAllBanner();
  };
  fetchBanners();
},[fetchAllBanner]);

  const onOpenModal = (productId) => {
    setOpenModal(true);
    setDataid(productId);
  };

  const addWishList = () => {
    history(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist/${layoutURL}`);
  };
  var images = require.context("../../../../assets/images", true);
  const dynamicImage = (image) => {
    return images(`./${image}`);
  };
  const navigateToBanner = (url) => {
    window.open(url, "_blank");
  };
  
  

  return (
    <Fragment>
      <div className="product-wrapper-grid" id="product-wrapper-grid">
        <Row className="gridRow" id="gridRow">
          {allBannerList?.map((item) => (
            <div id="gridId" className="col-xl-3 col-lg-6 col-sm-6 xl-4 box-col-4" key={item.id}>
              <Card>
                <div className="product-box">
                  <div className="product-img">
                    <Image attrImage={{ className: "img-fluid", src: item.banner_image, alt: "image" }} />
                    <div className="product-hover">
                      <UL attrUL={{ className: "simple-list d-flex flex-row" }}>
                        <LI attrLI={{ className: "border-0" }}>
                          <Button color="default" onClick={() => navigateToBanner(item.banner_url)}>
                            <i className="icon-link"></i>
                          </Button>
                        </LI>
                        <LI attrLI={{ className: "border-0" }}>
                          <Button color="default" data-toggle="modal" onClick={() => onOpenModal(item.id)}>
                            <i className="icon-eye"></i>
                          </Button>
                        </LI>
                        <LI attrLI={{ className: "border-0" }}>
                          <Button color="default" data-toggle="modal" onClick={() => deleteBanner(item.id)}>
                            <FaTrash/>
                          </Button>
                        </LI>
                      </UL>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          ))}
          {openModal && <ProductModal 
  value={openModal} 
  setOpenModal={setOpenModal} 
  dataid={dataid} 
  bannerData={allBannerList} 
/>
}
        </Row>
      </div>
    </Fragment>
  );
};
export default ProductGrid;
