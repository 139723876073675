import { create } from "zustand";
import { getSellerOrderById, getInvoiceFromOrder,fetchAllFilterOrderList } from "../Services/orderServices";

const orderStore = create((set, get) => ({
  updatePageSizeData: 1,
  updatePageCountData: 10,
  updateBusinessIdData: 0,
  totalRows: 0,

  allOrderList: [],
  modal: false,
  updatedOrderIdInvoiceList: [],
  UpdatedStatus: "Select Status",
  //for filter data
  NewFilterOrderData:{
    child_order_status:"",
    order_id:"",
    parent_order_status:"",
    payment_status:"",
    trx_id:""
  },
  filterOrderList:[],
  filterPageSizeData:1,
  filterPageCountData:10,
  totalFilterDataRows:0,
  errorMessage:"",
//fetch all order
fetchAllOrder: async () => {
    
  try {
    const { NewFilterOrderData, filterPageSizeData, filterPageCountData } = get();
    const response = await fetchAllFilterOrderList(filterPageCountData, filterPageSizeData, NewFilterOrderData);
    
    if (response.code === 200) {
      if (response.results && response.results.length > 0) { // Check if results array is not empty
        set({
          filterOrderList: response.results,
          totalFilterDataRows: response.count,
          errorMessage: "",
        });
      } else {
        set({
          filterOrderList: [],
          totalFilterDataRows: 0,
          errorMessage: response.message,
        });
      }
    } else {
      set({
        errorMessage: response.message,
      });
    }
  } catch (err) {
    console.error("Error while fetching filter data", err);
    set({ errorMessage: "An error occurred while fetching filter data." });
    throw err;
  }
},
  //Fetch all order Filter list 
  fetchOrderFilter: async () => {
    
    try {
      const { NewFilterOrderData, filterPageSizeData, filterPageCountData } = get();
      const response = await fetchAllFilterOrderList(filterPageCountData, filterPageSizeData, NewFilterOrderData);
      
      if (response.code === 200) {
        if (response.results && response.results.length > 0) { // Check if results array is not empty
          set({
            filterOrderList: response.results,
            totalFilterDataRows: response.count,
            errorMessage: "",
          });
        } else {
          set({
            filterOrderList: [],
            totalFilterDataRows: 0,
            errorMessage: response.message,
          });
        }
      } else {
        set({
          errorMessage: response.message,
        });
      }
    } catch (err) {
      console.error("Error while fetching filter data", err);
      set({ errorMessage: "An error occurred while fetching filter data." });
      throw err;
    }
  },

  //pagination for filter data
  updateFilterPaginationData: (page, pageSize) => {
    set({
      filterPageSizeData: page,
      filterPageCountData: pageSize,
    });
    get().fetchOrderFilter();
  },
  UpdateFilterOrderField: (e)=>
  {
    const {name,value} = e.target;
    set((state)=>({
      NewFilterOrderData:{...state.NewFilterOrderData,
        [name]:value,},
      
    }))
  },


//Fetch all order List
  fetchAllOrderList: async () => {
    try {
      const { updatePageSizeData, updatePageCountData, updateBusinessIdData } = get();
      const response = await getSellerOrderById(updateBusinessIdData, updatePageCountData, updatePageSizeData);
      if (response.code === 200) {
        set({
          allOrderList: response.results,
          totalRows: response.count,
          errorMessage: "",
        });
      } else {
        set({
          errorMessage: response.message,
        });
      }
    } catch (err) {
      console.log("Error while updating offer:", err);
      throw err;
    }
  },
  
  updateBusinessId: (businessId) => {
    set({ updateBusinessIdData: businessId });
    get().fetchAllOrderList();
  },
  updatePaginationData: (pageSize, countData) => {
    set({
      updatePageSizeData: pageSize,
      updatePageCountData: countData,
    });
    get().fetchAllOrderList();
  },
  fetchInvoiceFromOrderId: async (id) => {
    try {
      const response = await getInvoiceFromOrder(id);
      set(() => ({
        updatedOrderIdInvoiceList: response.data,
        UpdatedStatus: response?.data?.order_data?.order_status,
      }));
    } catch (err) {
      console.log("Error while fetching Invoice:", err);
      throw err;
    }
  },
  updateOrderId: (OrderId) => {
    set({
      updatedOrderId: OrderId,
    });
  },
}));

export default orderStore;
