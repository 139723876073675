import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import CommonModal from "../../../../../UiKits/Modals/common/modal";
import { useStore } from "zustand";
import bannerStore from "../../../../../../store/bannerStore";

const FormModal = ({ modal, NewMessage, toggle, defaultVal }) => {
  const { createBanner } =
    useStore(bannerStore);
  const store = bannerStore();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await createBanner();
      toggle();
    } catch (err) {
      console.error("Error while create Banner", err);
    }
  };
  return (
    <CommonModal
      isOpen={modal}
      title={"New Category"}
      toggler={toggle}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormGroup>
          <Label className="col-form-label" for="banner_url">
            {"Banner URL:"}
          </Label>
          <Input
            onChange={store.updateCreateBannerField}
            className="form-control"
            type="text"
            id="banner_url"
            name="banner_url"
            value={store.createNewBanner.banner_url}
          />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label" for="banner_image">
            {"Upload Banner Image:"}
          </Label>
          <Input
            onChange={store.updateCreateBannerField}
            type="file"
            className="form-control"
            id="banner_image"
            name="banner_image"
            // value={store.createNewCategoryData.image}
          />
        </FormGroup>
      </Form>
    </CommonModal>
  );
};

export default FormModal;
