import { create } from "zustand";
import {getAllBanner,createBanner,deleteBanner} from '../Services/bannerService'

// Helper function to convert file to Base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const bannerStore = create((set) => ({
  createNewBanner: {
    banner_url: "",
    banner_image: "",
  },
  id: null,
  allBannerList: [],
  modal: false,

  // Create a new banner
  createBanner: async () => {
    try {
      const { createNewBanner } = bannerStore.getState();
      
      const response = await createBanner(createNewBanner);
      set((state) => ({
        allBannerList: [...state.allBannerList, response.data],
        createNewBanner: {
            banner_url: "",
            banner_image: "",
          },
      }));
    } catch (error) {
      console.log("Error while creating Banner:", error);
      throw error;
    }
  },

  updateCreateBannerField: async (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const base64 = await fileToBase64(file);
      set((state) => ({
        createNewBanner: {
          ...state.createNewBanner,
          [name]: base64,
        },
      }));
    } else {
      set((state) => ({
        createNewBanner: {
          ...state.createNewBanner,
          [name]: value,
        },
      }));
    }
  },

  fetchAllBanner: async () => {
    try {
      const response = await getAllBanner();
      set((state) => ({
        allBannerList: response.data,
      }));
    } catch (err) {
      console.error("Error fetching Banner list:", err);
    }
  },

  

  // Close the edit modal
  closeEditModal: () => {
    set((state) => ({
      modal: false,
      updateCategoryData: {
        id: null,
        name: "",
        parent: 0,
        icon: "",
        image: "",
        description: "",
      },
    }));
  },

  deleteBanner: async (id) => {
    try {
      await deleteBanner(id);
      set((state) => ({
        allBannerList: state.allBannerList.filter((banner) => banner.id !== id),
      }));
    } catch (err) {
      console.error("Error while deleting banner:", err);
    }
  },
}));

export default bannerStore;
