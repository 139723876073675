import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"
//withdrawal list without shop
export const getPaymentGatewayHistory = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.fetchPgHistory);
        return response.data;
    }catch(err)
    {
        console.error("error while get payment gateway History list",err)
        throw err
    }
}
//Withdrawal list with Shop ID
export const getPaymentGatewayLog = async() =>
    {
        try{
            const response = await apiServices.get(apiEndpoints.fetchPgLogs);
            return response.data;
        }catch(err)
        {
            console.error("error while get gateway log list",err)
            throw err
        }
    }
//order Status changing

export default {getPaymentGatewayHistory,getPaymentGatewayLog}