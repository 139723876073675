import { Image, H3,H4, H6, P } from '../../../../../AbstractElements';
import { JohanDeo, JohanDeoMailId, MarginRight, ProjectDescription, ProjectDescriptionDetails } from '../../../../../Constant';
import { Col, Media, Row } from 'reactstrap';
import React, { Fragment } from 'react';
import user1 from '../../../../../assets/images/user/image.png';

const InvoiceDescription = ({data}) => {
  return (
    <Fragment>
      <Row className='invo-profile'>
        <Col xl='4'>
          <div className='invo-profile-left'>
            <Media>
              <div className='media-left'>
                <Image attrImage={{ className: 'media-object rounded-circle img-60', src: `${user1}`, alt: 'user' }} />
              </div>
              <Media body className='m-l-20' style={{ marginLeft: '20px' }}>
      <H3 attrH4={{ className: 'media-heading f-w-600'}} >Invoice To:</H3>
      <H4 attrH4={{ className: 'media-heading f-w-600' }}>{data?.order_data?.customer_name}</H4>
      <P>
        {data?.order_data?.customer_address}
        <br/>
        {data?.order_data?.customer_email}
        <br />
        <span className='digits'>{data?.order_data?.customer_mobile}</span>
      </P>
    </Media>
            </Media>
          </div>
        </Col>
        <Col xl='8'>
          <div className='text-md-end'>
            <div id='project'>
              <H3>{"Invoice From:"}</H3>
              <P>{data?.site_info?.site_name}
              <br/>
              {data?.site_info?.company_address}<br/>
              {data?.site_info?.support_info?.mobile}<br/>
              {data?.site_info?.support_info?.email}</P>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default InvoiceDescription;
