import { Breadcrumbs } from '../../../../AbstractElements';
import ProductGrid from './ProductGrid';
import React from 'react';
import { Fragment } from 'react';
import { Container } from 'reactstrap';
import { useState } from 'react';
import Modals from './Modals'

const ProductContain = () => {
    const [modal, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modal);
    };
    return (
        <Fragment>
            <Breadcrumbs parent="Ecommerce" title="Banner" mainTitle="Banners" />
            <Container fluid={true} className="product-wrapper" id="product-wrapper">
                <div className="product-grid">
                <Modals isOpen={modal} toggle={handleModal} />
                    <ProductGrid />
                </div>
            </Container>
        </Fragment>
    );
};
export default ProductContain;