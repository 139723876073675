import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllBanner = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.get_all_banner)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in banner:",err);
        throw err
    }
}

export const createBanner = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.add_banner,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create banner:",err)
        throw err
    }
}

export const deleteBanner = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.delete_banner+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a banner:",err);
        throw err
    }
}




export default {
   getAllBanner,
   createBanner,
   deleteBanner,
}
