import { H4, LI, P, Image, UL } from "../../../../AbstractElements";
import ProductModal2 from "./ProductModal2";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Row, Card, Button } from "reactstrap";
import { useStore } from "zustand";
import useAdStore from "../../../../store/adStore";
import { FaTrash } from 'react-icons/fa';

const ProductGrid2 = () => {
  const { adSection2, fetchAdSection2 } = useStore(useAdStore);
  const [dataid, setDataid] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const history = useNavigate();

  // Load all banners for ad section 1
  useEffect(() => {
    fetchAdSection2(); // Fetching ad section 1 when the component mounts
  }, [fetchAdSection2]);

  const onOpenModal = (productId) => {
    setOpenModal(true);
    setDataid(productId);
  };


  const navigateToBanner = (url) => {
    window.open(url, "_blank");
  };

  const deleteBanner = (bannerId) => {
    // Add your delete logic here
    console.log(`Deleting banner with ID: ${bannerId}`);
  };

  // Extract the data array from adSection1
  const banners = adSection2.length > 0 && adSection2[0]?.data ? adSection2[0].data : [];
  console.log('ad',banners)

  return (
    <Fragment>
      <div className="product-wrapper-grid" id="product-wrapper-grid">
        <Row className="gridRow" id="gridRow">
          {banners.length > 0 ? (
            banners.map((item) => (
              <div id="gridId" className="col-xl-3 col-lg-6 col-sm-6 xl-4 box-col-4" key={item.id}>
                <Card>
                  <div className="product-box">
                    <div className="product-img">
                      <Image attrImage={{ className: "img-fluid", src: item?.ad_image, alt: "image" }} />
                      <div className="product-hover">
                        <UL attrUL={{ className: "simple-list d-flex flex-row" }}>
                          <LI attrLI={{ className: "border-0" }}>
                            <Button color="default" onClick={() => navigateToBanner(item.ad_url)}>
                              <i className="icon-link"></i>
                            </Button>
                          </LI>
                          <LI attrLI={{ className: "border-0" }}>
                            <Button color="default" data-toggle="modal" onClick={() => onOpenModal(item.id)}>
                              <i className="icon-eye"></i>
                            </Button>
                          </LI>
                          <LI attrLI={{ className: "border-0" }}>
                            <Button color="default" onClick={() => deleteBanner(item.id)}>
                              <FaTrash />
                            </Button>
                          </LI>
                        </UL>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ))
          ) : (
            <p>No banners available</p> 
          )}
          {openModal && (
            <ProductModal2
              value={openModal} 
              setOpenModal={setOpenModal} 
              dataid={dataid} 
              bannerData={banners} 
            />
          )}
        </Row>
      </div>
    </Fragment>
  );
};

export default ProductGrid2;
