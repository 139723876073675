import { create, set } from "zustand";
import { getAllCustomer } from "../Services/customerService";

const customerStore = create((set) => ({
  filteredList: [],
  list: [],
  fetchAllCustomer: async () => {
    try {
      const response = await getAllCustomer();
      set((state) => ({
        filteredList: response.data,
        list: response.data,
      }));
    } catch (err) {
      console.error("Error fetching customer list:", err);
    }
  },
  updateFilteredList: (data) => {
    set((state) => ({
      filteredList: data,
    }));
  },
}));

export default customerStore;
