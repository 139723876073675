import { create } from "zustand";
import {
  getAllOffers,
  createOffer,
  deleteOffer,
  updateOfferSetting,
  getOfferSetting
} from "../Services/offerService";

const offerStore = create((set, get) => ({
  createNewDealData: {
    product: 0,
    sale_price: "",
  },
  errorMessage: "",
  id: null,
  updateDealData: {
    product: 0,
    sale_price: "",
  },

  allOfferList: [],
  modal: false,
  updateOfferSettingData: {
    title: "",
    start_timestamp: new Date(),
    end_timestamp: new Date(),
    status: false,
    countdown: false,
  },
  updateSettingOfferList: [],

  // Update or create offer setting
  updateOfferSetting: async () => {
    try {
      const { updateOfferSettingData } = offerStore.getState();
      const response = await updateOfferSetting(updateOfferSettingData);
      if (response.code === 200) {
        set((state) => ({
          updateOfferSettingData: {
            ...state.updateOfferSettingData,
            ...response.data
          },
          errorMessage: "",
        }));
      } else {
        set((state) => ({
          errorMessage: response.message,
        }));
      }
    } catch (err) {
      console.log("Error while updating offer:", err);
      throw err;
    }
  },

  fetchUpdateOfferSetting: async () => {
    try {
      const response = await getOfferSetting();
      console.log("response", response.data);

      set((state) => ({ 
        updateOfferSettingData: {
          ...state.updateOfferSettingData,
          ...response.data,
          start_timestamp: new Date(response.data.start_timestamp),
          end_timestamp: new Date(response.data.end_timestamp)
        }
      }));

      

    } catch (err) {
      console.error("Error fetching update offer setting list:", err);
    }
  },

  updateOfferSettingField: ({ name, value, type, checked }) => {
    set((state) => ({
      updateOfferSettingData: {
        ...state.updateOfferSettingData,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  },

  // For storing date field
  updateOfferSettingDateField: (name, date) => {
    set((state) => ({
      updateOfferSettingData: {
        ...state.updateOfferSettingData,
        [name]: date,
      },
    }));
  },

  // Create a new category
  createOffer: async () => {
    try {
      const { createNewDealData } = offerStore.getState();
      const response = await createOffer(createNewDealData);
      if (response.code === 200) {
        set((state) => ({
          allOfferList: [...state.allOfferList, response.data],
          createNewDealData: {
            product: 0,
            sale_price: "",
          },
          errorMessage: "",
        }));
      } else {
        set((state) => ({
          errorMessage: response.message,
        }));
      }
    } catch (error) {
      console.log("Error while creating offer:", error);
      throw error;
    }
  },

  updateCreateOfferField: async (e) => {
    const { name, value } = e.target;
    set((state) => ({
      createNewDealData: {
        ...state.createNewDealData,
        [name]: value,
      },
    }));
  },

  fetchAllOfferList: async () => {
    try {
      const response = await getAllOffers();
      set((state) => ({
        allOfferList: response.data,
      }));
    } catch (err) {
      console.error("Error fetching offer list:", err);
    }
  },

  // Close the edit modal
  closeEditModal: () => {
    set((state) => ({
      modal: false,
      updateDealData: {
        product: 0,
        sale_price: "",
      },
    }));
  },

  updateEditCategoryField: async (e) => {
    const { name, value } = e.target;
    set((state) => ({
      updateDealData: {
        ...state.updateDealData,
        [name]: value,
      },
    }));
  },

  deleteOfferProduct: async (id) => {
    try {
      await deleteOffer(id);
      set((state) => ({
        allOfferList: state.allOfferList.filter((offer) => offer.id !== id),
      }));
    } catch (err) {
      console.error("Error while deleting offer:", err);
    }
  },
}));

export default offerStore;
