export const OrderHistoryDataTable = {
  productRow: [
    {
      product: '1.png',
      productName: 'Long Top',
      size: 'M',
      color: 'Lavander',
      articleNumber: '4215738',
      units: '11',
      price: '$21',
      status: 'Processing',
    },
    {
      product: '13.png',
      productName: 'Fancy Watch',
      size: '35 mm',
      color: 'Blue',
      articleNumber: '5476182',
      units: '11',
      price: '$10',
      status: 'Processing',
    },
    {
      product: '4.png',
      productName: 'Man Shoes',
      size: '8',
      color: 'Black & White',
      articleNumber: '1756457',
      units: '11',
      price: '$ 18',
      status: 'Processing',
    },
    {
      product: '10.png',
      productName: 'Ledis side bag',
      size: '22cm x 18cm',
      color: 'Brown',
      articleNumber: '7451725',
      units: '1',
      price: '$13',
      status: 'Shipped',
      statusCode: 'shipped-order',
    },
    {
      product: '12.png',
      productName: 'ledis slipper',
      size: '6',
      color: 'Brown',
      articleNumber: '4127421',
      units: '1',
      price: '$6',
      status: 'Shipped',
      statusCode: 'shipped-order',
    },
    {
      product: '3.png',
      productName: 'Fancy ledis Jacket',
      size: 'Xl',
      color: 'Light Gray',
      articleNumber: '3581714',
      units: '1',
      price: '$24',
      status: 'Shipped',
      statusCode: 'shipped-order',
    },
    {
      product: '2.png',
      productName: 'Ledis Handbag',
      size: '25x25',
      color: 'Black',
      articleNumber: '6748142',
      units: '1',
      price: '$12',
      status: 'Cancelled',
      statusCode: 'cancel-order',
    },
    {
      product: '15.png',
      productName: 'Iphone 6',
      size: '15x15',
      color: 'Gold',
      articleNumber: '5748214',
      units: '1',
      price: '$25',
      status: 'Cancelled',
      statusCode: 'cancel-order',
    },
    {
      product: '14.png',
      productName: 'Slippers',
      size: '6',
      color: 'Blue',
      articleNumber: '8475112',
      units: '1',
      price: '$6',
      status: 'Processing',
      statusCode: 'cancel-order',
    },
  ],

  productColumns: [
    {
      name: 'Product',
      selector: (row) => row.mainOrderId,
      sortable: true,
      center: true,
      minWidth: '150px',
      maxWidth: '200px',
    },
    {
      name: 'Order ID',
      selector: (row) => row.orderId,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: '200px',
      maxWidth: '420px',
    },
    {
      name: 'Total Amount',
      selector: (row) => row.totalAmount,
      sortable: true,
      center: true,
      minWidth: '100px',
      maxWidth: '150px',
    },
    {
      name: 'Order Status',
      selector: (row) => row.orderStatus,
      sortable: true,
      center: true,
      minWidth: '120px',
      maxWidth: '150px',
    },
    {
      name: 'Main Order Status',
      selector: (row) => row.mainOrderStatus,
      sortable: true,
      center: true,
      minWidth: '120px',
      maxWidth: '180px',
    },
    {
      name: 'Payment Status',
      selector: (row) => row.paymentStatus,
      sortable: true,
      center: true,
      minWidth: '160px',
      maxWidth: '160px',
    },
    {
      name: 'Order Date',
      selector: (row) => row.orderDate,
      sortable: true,
      center: true,
      minWidth: '160px',
      maxWidth: '160px',
    },
  ],
};
