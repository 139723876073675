import { create } from 'zustand';
import adService from '../Services/adService';// Assuming this is the service file name with the API calls

// Helper function to convert file to Base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const useAdStore = create((set, get) => ({
  adNumber: 1,
  createAd: {
    banner_url: "",
    banner_image: "",

  },
  createAd1:{
    ad_url: "",
    ad_image: "",
  },
  adSection1: [],
  adSection2: [],
  adSection3: [],
  loading: false,
  error: null,

  // Fetch all ads for section 1
  fetchAdSection1: async () => {
    set({ loading: true, error: null });
    try {
      const data = await adService.getAllAd1();
      set({ adSection1: [data], loading: false });
    } catch (err) {
      set({ error: err.message, loading: false });
    }
  },

  // Update fields for creating ads
  updateCreateAdsField: async (e) => {
    const { name, value, files } = e.target;

    if (name === "adNumber") {
      set({ adNumber: parseInt(value, 10) });  // Ensure the adNumber is updated properly
    } else if (files && files.length > 0) {
      const file = files[0];
      try {
        const base64 = await fileToBase64(file);
        set((state) => ({
          createAd: {
            ...state.createAd,
            [name]: base64,
          },
        }));
      } catch (error) {
        set({ error: "Failed to convert file to Base64" });
      }
    } else {
      set((state) => ({
        createAd: {
          ...state.createAd,
          [name]: value,
        },
      }));
    }
  },
  //for ad2
  updateCreateAds1Field: async (e) => {
    const { name, value, files } = e.target;

    if (name === "adNumber") {
      set({ adNumber: parseInt(value, 10) });  // Ensure the adNumber is updated properly
    } else if (files && files.length > 0) {
      const file = files[0];
      try {
        const base64 = await fileToBase64(file);
        set((state) => ({
          createAd1: {
            ...state.createAd1,
            [name]: base64,
          },
        }));
      } catch (error) {
        set({ error: "Failed to convert file to Base64" });
      }
    } else {
      set((state) => ({
        createAd1: {
          ...state.createAd1,
          [name]: value,
        },
      }));
    }
  },

  // Update ad section 1
  updateAdSection1: async () => {
    set({ loading: true, error: null });
    try {
      const { adNumber,fetchAdSection1 } = get();
      const { createAd } = get();
      const updatedData = await adService.updateAdSection1(adNumber, createAd);
      fetchAdSection1();
      set((state) => ({
        adSection1: [
          state.adSection1.map((ad) =>
            ad.id === updatedData.id ? updatedData : ad
          ),
        ],
        loading: false,
        createAd: {
          banner_url: "",
          banner_image: "",
          ad_url: "",
          ad_image: "",
        },
      }));
    } catch (err) {
      set({ error: err.message, loading: false });
    }
  },
  // Fetch all ads for section 2
  fetchAdSection2: async () => {
    set({ loading: true, error: null });
    try {
      const data = await adService.getAllAd2();
      set({ adSection2: [data], loading: false });
    } catch (err) {
      set({ error: err.message, loading: false });
    }
  },

  // Update ad section 2
  updateAdSection2: async () => {
    set({ loading: true, error: null });
    try {
        const {adNumber,fetchAdSection2} = get();
      const { createAd1 } = get();
      console.log(createAd1)
      const updatedData = await adService.updateAdSection2(adNumber,createAd1);
      fetchAdSection2();
      set((state) => ({
        adSection2: [state.adSection2.map((ad) => (ad.id === updatedData.id ? updatedData : ad))],
        loading: false,
        createAd1:{
          ad_url: "",
          ad_image: "",
        },
      }));
    } catch (err) {
      set({ error: err.message, loading: false });
    }
  },

  // Fetch all ads for section 3
  fetchAdSection3: async () => {
    set({ loading: true, error: null });
    try {
      const data = await adService.getAllAd3();
      set({ adSection3: [data], loading: false });
    } catch (err) {
      set({ error: err.message, loading: false });
    }
  },

  // Update ad section 3
  updateAdSection3: async () => {
    set({ loading: true, error: null });
    try {
        const {adNumber,fetchAdSection3} = get();
      const { createAd1 } = get();
      const updatedData = await adService.updateAdSection3(adNumber,createAd1);
      fetchAdSection3()
      set((state) => ({
        adSection3: [state.adSection3.map((ad) => (ad.id === updatedData.id ? updatedData : ad))],
        loading: false,
        createAd: {
          banner_url: "",
          banner_image: "",
          ad_url: "",
    ad_image: "",
        },
      }));
    } catch (err) {
      set({ error: err.message, loading: false });
    }
  },

  // Other methods for ad sections 2 and 3 ...
}));

export default useAdStore;
