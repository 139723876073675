import React, { Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import { productColumns } from '../../../../Data/Ecommerce/ProductList';
import ProductList from '../../../../Data/Ecommerce/ProductList';
import categoryStore from '../../../../store/categoryStore';
import EditCategoryModal from '../../../UiKits/Modals/VaryingContent/common/editCategoryFormModal';

const ProductTableData = () => {
  const store = categoryStore();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const toggleModal = () => setModal(!modal);

  const handleButtonClick = (content) => {
    setModalContent(content);
    toggleModal();
  };
  return (
    <Fragment>
      <ProductList setData={setData} handleClick={handleButtonClick}/>
      <div className='table-responsive product-table'>
        <DataTable
          noHeader
          pagination
          paginationServer
          columns={productColumns}
          data={data}
          highlightOnHover={true}
          striped={true}
          responsive={true}
        />
      </div>
      <EditCategoryModal/>
    </Fragment>
  );
};

export default ProductTableData;
