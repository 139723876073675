import React, { Fragment, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import { useStore } from "zustand";
import DatePicker from "react-datepicker";
import offerStore from "../../store/OfferStore";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import ToggleSwitch from "../../Components/WindowBusinessOnboardList/toggleButton";
import { Btn } from "../../AbstractElements";

const DealSettingForm = () => {
  const {
    updateOfferSettingData,
    updateOfferSetting,
    fetchUpdateOfferSetting,
    updateOfferSettingField,
    updateOfferSettingDateField,
  } = useStore(offerStore);

  useEffect(() => {
    fetchUpdateOfferSetting();
  }, [fetchUpdateOfferSetting]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await updateOfferSetting();
      await fetchUpdateOfferSetting();
    } catch (err) {
      console.error("Error while updating offer setting:", err);
    }
  };

  const handleToggleField = (name, value) => {
    updateOfferSettingField({ name, type: 'checkbox', checked: value });
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  return (
    <Fragment>
      <Card>
        <HeaderCard title={"Setting"} />
        <Form className="form theme-form" onSubmit={handleSubmit}>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="exampleFormControlInput1">
                    Title of This Section
                  </Label>
                  <Input
                    onChange={(e) => updateOfferSettingField(e.target)}
                    className="form-control"
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={updateOfferSettingData.title || "Ex: Monday Blast!"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">
                  Start Date:
                </Label>
                <Col xl="5" sm="7" lg="8">
                  <InputGroup
                    className="date flex-nowrap"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control digits"
                      selected={isValidDate(new Date(updateOfferSettingData.start_timestamp)) ? new Date(updateOfferSettingData.start_timestamp) : null}
                      showTimeSelect
                      onChange={(date) => updateOfferSettingDateField('start_timestamp', date)}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                    <div
                      className="input-group-text"
                      data-target="#dt-local"
                      data-toggle="datetimepicker"
                    >
                      <i className="fa fa-calendar"></i>
                    </div>
                  </InputGroup>
                </Col>
              </Row>
              <Col>
                <Row className="mb-3 g-3">
                  <Label className="col-sm-3 col-form-label">
                    End Date:
                  </Label>
                  <Col xl="5" sm="7" lg="8">
                    <InputGroup
                      className="date flex-nowrap"
                      id="dt-minimum"
                      data-target-input="nearest"
                    >
                      <DatePicker
                        className="form-control digits"
                        selected={isValidDate(new Date(updateOfferSettingData.end_timestamp)) ? new Date(updateOfferSettingData.end_timestamp) : null}
                        showTimeSelect
                        onChange={(date) => updateOfferSettingDateField('end_timestamp', date)}
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                      <div
                        className="input-group-text"
                        data-target="#dt-local"
                        data-toggle="datetimepicker"
                      >
                        <i className="fa fa-calendar"></i>
                      </div>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm="6">
                <Row>
                  <Label className="col-sm-3 col-form-label">
                    Status
                  </Label>
                  <Col sm="9">
                    <ToggleSwitch
                      value={updateOfferSettingData.status}
                      onToggle={(value) => handleToggleField('status', value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm="6">
                <Row>
                  <Label className="col-sm-3 col-form-label">
                    Countdown
                  </Label>
                  <Col sm="9">
                    <ToggleSwitch
                      value={updateOfferSettingData.countdown}
                      onToggle={(value) => handleToggleField('countdown', value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit" }}>Submit</Btn>
          </CardBody>
          
        </Form>
      </Card>
    </Fragment>
  );
};

export default DealSettingForm;
