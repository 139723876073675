import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const allBusinessType = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getBusinessType)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in all business Type list:",err);
        throw err
    }
}

export const createBusinessType = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.create_business_type,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create business Type:",err)
        throw err
    }
}

export const deleteBusinessType = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.deleteBusinessType+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a Business Type:",err);
        throw err
    }
}

export const editBusinessType = async(param,data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.editBusinessType+param,data)
        return response.data
    }catch(err)
    {
        console.error("Error while editing Business type:",err);
        throw err
    }
}


export default {
    allBusinessType,
    createBusinessType,
    deleteBusinessType,
    editBusinessType
}
