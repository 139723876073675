import React, { Fragment, useState } from 'react';
import { H6, Image, P } from '../../../../../AbstractElements';
import { Col, Nav, NavLink } from 'reactstrap';
import CreateContact from './CreateContact';

const ListNewContact = ({ users, userCallback }) => {
  const [dynamictab, setDynamicTab] = useState('0');

  const ContactDetails = (user) => {
    userCallback({ id: user.id, name: user.name,});
  };

  return (
    <Fragment>
      <Col xl='4' md='5' className='xl-50'>
        <Nav tag='div' className='flex-column nav-pills'>
          {users.length > 0 ? (
            users.map((user, index) => {
              return (
                <NavLink className={dynamictab === index ? 'active' : ''} onClick={() => setDynamicTab(index)} key={index}>
                  <div className='media' onClick={() => ContactDetails(user)}>
                    <div className='media-body'>
                      <H6>
                        <span className='first_name_0'>{user.name}</span>&nbsp;
                      </H6>
                    </div>
                  </div>
                </NavLink>
              );
            })
          ) : (
            <div className='p-4'>
              You need to create new Country.Please click on button.
              <CreateContact empty={users.length > 0 ? true : false}>
                <b>New Country</b>
              </CreateContact>
            </div>
          )}
        </Nav>
      </Col>
    </Fragment>
  );
};

export default ListNewContact;
