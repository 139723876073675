import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../../AbstractElements';
import { ProductCategoryListDesc, ProductCategoryTitle } from '../../../../Constant';
import ProductTableData from './ProductTableData';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';
import VaryingContent from '../../../UiKits/Modals/VaryingContent';

const ProductListContain = () => {
    const [modal, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modal);
    };

    return (
        <Fragment>
            <Breadcrumbs parent="Ecommerce" title="Products List" mainTitle="Products List" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <VaryingContent isOpen={modal} toggle={handleModal} />
                            
                            <HeaderCard title={ProductCategoryTitle} span1={ProductCategoryListDesc} />

                            <CardBody>
                                <ProductTableData />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
        </Fragment>
    );
};

export default ProductListContain;
