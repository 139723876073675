import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import OrderHistoryTable from './OrderHistoryTable';
import DropDown from './dropDown';

const OrderHistoryTableCard = () => {
  return (
    <Col>
      <Card>
      <DropDown/>
        <CardHeader>
          <H5>Seller Shipping Order List</H5>
        </CardHeader>
        <CardBody>
          <OrderHistoryTable />
        </CardBody>
      </Card>
    </Col>
  );
};

export default OrderHistoryTableCard;