import React from 'react';
import { Col, Row, Button, Progress } from 'reactstrap';
import { H6, Image, LI, P, UL } from '../../../../AbstractElements';
import couponStore from '../../../../store/couponStore';

const CusClass = ({ item }) => {
  const { toggleCouponStatusById, deleteCouponById } = couponStore();

  // Handle status toggle
  const handleToggleStatus = async () => {
    await toggleCouponStatusById(item.id);
  };

  // Handle delete
  const handleDelete = async () => {
    await deleteCouponById(item.id);
  };

  // Handle edit
  const handleEdit = () => {
    // Logic to open edit modal or navigate to edit page
    console.log("Edit coupon:", item.id);
  };

  // Helper function to get image source
  const getImageSrc = (imgName) => {
    try {
      return require(`../../../../assets/images/${imgName}`);
    } catch (e) {
      return ''; // Return an empty string or a default image path
    }
  };

  // Calculate progress based on usage and limit
  const progress = item.usage_limit > 0 ? (item.total_used / item.usage_limit) * 100 : 0;

  return (
    <Col className='col-xxl-4' md='6'>
      <div className='project-box'>
        <span className={`badge ${item.status ? 'badge-success' : 'badge-danger'}`}>
          {item.status ? 'Active' : 'Inactive'}
        </span>
        <H6>{item.coupon_code}</H6>
        <div className='media'>
          <Image
            attrImage={{
              className: 'img-20 me-1 rounded-circle',
              src: getImageSrc(item.img),
              alt: item.coupon_code,
            }}
          />
          <div className='media-body'>
            <P>Type: {item.coupon_type}</P>
            <P>value: {item.coupon_value}</P>
            <P>Start Date: {new Date(item.start_date).toLocaleDateString()}</P>
            <P>End Date: {new Date(item.end_date).toLocaleDateString()}</P>
          </div>
        </div>
        <Row className='details'>
          <Col xs='6'>
            <span>Usage Limit: {item.usage_limit}</span>
          </Col>
          <Col xs='6' className={item.status ? 'font-success' : 'font-primary'}>
            Total Used: {item.total_used}
          </Col>
          <Col xs='12' className='mt-3'>
            <Button
              color={item.status ? 'success' : 'danger'}
              size="sm"
              onClick={handleToggleStatus}
            >
              {item.status ? 'Deactivate' : 'Activate'}
            </Button>
          </Col>
          <Col xs='6' className='mt-3'>
            <Button color='primary' size="sm" onClick={handleEdit}>
              Edit
            </Button>
          </Col>
          <Col xs='6' className='mt-3'>
            <Button color='danger' size="sm" onClick={handleDelete}>
              Delete
            </Button>
          </Col>
        </Row>
        <div className='project-status mt-4'>
          <div className='media mb-0'>
            <P>Progress: {Math.round(progress)}%</P>
            <div className='media-body text-end'>
              <span>Done</span>
            </div>
          </div>
          <Progress
            className='sm-progress-bar'
            color={progress === 100 ? 'success' : 'primary'}
            striped={progress !== 100}
            value={progress}
            style={{ height: '5px' }}
          />
        </div>
      </div>
    </Col>
  );
};

export default CusClass;
