import React, { Fragment, useState } from 'react';
import { Col, Card, CardHeader, Row } from 'reactstrap';
import { H6, Image } from '../../../AbstractElements';
import { BOD, ContactUs, Email, Location } from '../../../Constant';
import { useStore } from 'zustand';
import userStore from '../../../store/userStore';
import UserEditModal from './UserEditModal';  // Import the modal component

const UserProfile = () => {
  const [url, setUrl] = useState('');
  const [modalOpen, setModalOpen] = useState(false);  // Modal state
  const { user } = useStore(userStore);

  const toggleModal = () => setModalOpen(!modalOpen);  // Function to toggle modal

  return (
    <Fragment>
      <Col sm='12'>
        <Card className='hovercard text-center'>
          <CardHeader className='cardheader'></CardHeader>
          <div className='user-image'>
            <div className='avatar'>
              <Image attrImage={{ className: 'step1', alt: '', src: `${url ? url : require('../../../assets/images/user/7.jpg')}` }} />
            </div>
            <div className='icon-wrapper step2' data-intro='Change Profile image here'>
              <i className='icofont icofont-pencil-alt-5' onClick={toggleModal}></i>  {/* Open modal */}
            </div>
          </div>
          <div className='info'>
            <Row className='step3' data-intro='This is the your details'>
              <Col sm='6' lg='4' className='order-sm-1 order-xl-0'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start'>
                      <H6>
                        <i className='fa fa-envelope me-2'></i> {Email}
                      </H6>
                      <span>{user?.email}</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-calendar me-2'></i>
                        {BOD}
                      </H6>
                      <span>{user?.dob || 'DD/MM/YYYY'}</span>  {/* Display date of birth */}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' lg='4' className='order-sm-0 order-xl-1'>
                <div className='user-designation'>
                  <div className='title'>
                    <a target='_blank' href='#javascript'>
                      {user?.first_name + " " + user?.last_name}
                    </a>
                  </div>
                  <div className='desc mt-2'>Admin Controller</div>
                </div>
              </Col>
              <Col sm='6' lg='4' className='order-sm-2 order-xl-2'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-xs-mt'>
                      <H6>
                        <i className='fa fa-phone me-2'></i>
                        {ContactUs}
                      </H6>
                      <span>018XXXXXXXX</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-location-arrow me-2'></i>
                        {Location}
                      </H6>
                      <span>Bangladesh</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
          </div>
        </Card>
      </Col>
      <UserEditModal isOpen={modalOpen} toggle={toggleModal} />  {/* Include modal */}
    </Fragment>
  );
};

export default UserProfile;
