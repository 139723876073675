import React, { Fragment, useState, useEffect } from 'react';
import { Btn, Image } from '../../../../AbstractElements';
import { Modal, Col } from 'reactstrap';

const ProductModal = (props) => {
  const [open, setOpen] = useState(props.value);
  const [singleProduct, setSingleProduct] = useState({});

  useEffect(() => {
    setOpen(props.value); // Update the state if props.value changes
    // Assuming props.dataid is the key to fetch or identify the single product from props.bannerData
    const product = props.bannerData.find(item => item.id === props.dataid);
    setSingleProduct(product || {});
  }, [props.value, props.dataid, props.bannerData]);

  const onCloseModal = () => {
    setOpen(false);
    props.setOpenModal(false);
  };

  return (
    <Fragment>
      <Modal className='modal-dialog modal-lg modal-dialog-centered product-modal' isOpen={open} toggle={onCloseModal}>
        <div className='modal-body'>
          <div className='modal-header'>
            <div className='modal-title'>
              <div className='product-box row'>
                <Col lg='12'>
                  <Image
                    attrImage={{
                      className: 'img-fluid',
                      src: singleProduct.banner_image || '',
                      alt: singleProduct.banner_image ? 'Product Image' : '',
                    }}
                  />
                </Col>
              </div>
            </div>
            <Btn attrBtn={{ color: 'transparent', className: 'btn-close', onClick: onCloseModal, type: 'button', 'data-bs-dismiss': 'modal', 'aria-label': 'Close' }}></Btn>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProductModal;
