import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import DataTableComponent from '../../Components/WindowCustomer/DataTableComponent';

const CustomerPage = () => {

  return (
    <Fragment>
      <Breadcrumbs parent="Table" title="Customers" mainTitle="Customers" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="All Customers List" />
              <CardBody>
                <DataTableComponent />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default CustomerPage;