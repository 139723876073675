import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { useParams } from "react-router";
import { editOrderStatus } from "../../../../Services/orderServices";
import orderStore from "../../../../store/orderStore";
import { FaChevronDown } from "react-icons/fa"; // Import the dropdown icon
import './MainOrder.css'; // Import the CSS file for additional styling

const DropDown = () => {
  const { UpdatedStatus, fetchInvoiceFromOrderId } = orderStore();
  const { id } = useParams();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectBtnName, setSelectBtnName] = useState("Select Status");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSelect = async (value) => {
    await editOrderStatus(id, value);
    setSelectBtnName(value);
    setDropdownOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fetchInvoiceFromOrderId) {
        await fetchInvoiceFromOrderId(id);
      }
    };
    fetchData();
  }, [fetchInvoiceFromOrderId, id]);

  useEffect(() => {
    if (UpdatedStatus) {
      setSelectBtnName(UpdatedStatus);
    }
  }, [UpdatedStatus]);

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Pending':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'Processing':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'On Hold':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'Shipped':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'In Hub':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'Delivered':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'Cancelled':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'Refunded':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      case 'Returned':
        return {  fontWeight: 'bold', padding: '5px', borderRadius: '4px' };
      default:
        return { fontWeight: 'bold' };
    }
  };

  return (
    <div>    
      <Col sm="12" xl="12">
        <Card>
          <HeaderCard title={"Select Order Status"} />
          <CardBody>
            <div className="dropdown-basic">
              <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown">
                <DropdownToggle tag="div" className="dropbtn-custom">
                  <Btn attrBtn={{ color: "green", className: "dropbtn", style: getStatusStyle(selectBtnName) }}>
                    {selectBtnName}
                    <span>
                      <FaChevronDown className="dropdown-icon" />
                    </span>
                  </Btn>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-custom">
                  <DropdownItem onClick={() => handleSelect("Pending")}>Pending</DropdownItem>
                  <DropdownItem onClick={() => handleSelect("Processing")}>Processing</DropdownItem>
                  <DropdownItem onClick={() => handleSelect("On Hold")}>On Hold</DropdownItem>
                  <DropdownItem onClick={() => handleSelect("Shipped")}>Shipped</DropdownItem>
                  <DropdownItem onClick={() => handleSelect("Delivered")}>Delivered</DropdownItem>
                  <DropdownItem onClick={() => handleSelect("Cancelled")}>Cancelled</DropdownItem>
                  <DropdownItem onClick={() => handleSelect("Returned")}>Returned</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default DropDown;
