import React, { Fragment, useEffect } from 'react';
import { Col, FormGroup, Label, Row, Button, Alert, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import couponStore from '../../../store/couponStore';
import { Link } from 'react-router-dom';
import { Btn } from '../../../AbstractElements';
import { Cancel } from '../../../Constant';

const CouponForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  // Access Zustand store functions and state
  const { createNewCoupon, updateCouponField, couponData, message, set, clearMessage } = couponStore();

  // Handle form submission
  const onSubmit = () => {
    createNewCoupon(couponData);
  };

  // Handle field updates
  const handleInputChange = (e) => {
    updateCouponField(e);
  };
  useEffect(() => {
    if (message) {
      clearMessage();
    }
  }, [message, clearMessage]);
  // Clear the message after displaying the alert
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        set(() => ({
          message: null,
        }));
      }, 3000); // Hide the alert after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [message, set]);
  console.log(couponData?.exclude_category, "lasfj alfj")
  return (
    <Fragment>
      {/* Alert for displaying messages */}
      {message && <Alert color="success">{message}</Alert>}
      <Form className='theme-form' onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <FormGroup>
            <Label>Coupon Code</Label>
            <input
              className="form-control"
              type="text"
              name="coupon_code"
              placeholder="Coupon Code *"
              {...register('coupon_code', { required: true })}
              value={couponData.coupon_code}
              onChange={handleInputChange}
            />
            <span style={{ color: 'red' }}>{errors.coupon_code && 'Coupon Code is required'}</span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>Coupon Type</Label>
            <input
              className="form-control"
              type="text"
              name="coupon_type"
              placeholder="Percentage/Fixed?"
              {...register('coupon_type', { required: true })}
              value={couponData.coupon_type}
              onChange={handleInputChange}
            />
            <span style={{ color: 'red' }}>{errors.coupon_type && 'Coupon Type is required'}</span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>Coupon Value</Label>
            <input
              className="form-control"
              type="number"
              name="coupon_value"
              placeholder="Coupon Value *"
              {...register('coupon_value', { required: true })}
              value={couponData.coupon_value || ''}
              onChange={handleInputChange}
            />
            <span style={{ color: 'red' }}>{errors.coupon_value && 'Coupon Value is required'}</span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>Usage Limit</Label>
            <input
              className="form-control"
              type="number"
              name="usage_limit"
              placeholder="Usage Limit *"
              {...register('usage_limit', { required: true })}
              value={couponData.usage_limit || ''}
              onChange={handleInputChange}
            />
            <span style={{ color: 'red' }}>{errors.usage_limit && 'Usage Limit is required'}</span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>Start Date</Label>
            <input
              className="form-control"
              type="date"
              name="start_date"
              {...register('start_date', { required: true })}
              value={couponData.start_date ? couponData.start_date.toISOString().split('T')[0] : ''}
              onChange={handleInputChange}
            />
            <span style={{ color: 'red' }}>{errors.start_date && 'Start Date is required'}</span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>End Date</Label>
            <input
              className="form-control"
              type="date"
              name="end_date"
              {...register('end_date', { required: true })}
              value={couponData.end_date ? couponData.end_date.toISOString().split('T')[0] : ''}
              onChange={handleInputChange}
            />
            <span style={{ color: 'red' }}>{errors.end_date && 'End Date is required'}</span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label>Exclude Category</Label>
            <input
              className="form-control"
              type="text"
              name="exclude_category"
              placeholder="Exclude Category"
              {...register('exclude_category')}
              value={couponData?.exclude_category?.join(', ')}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row >
        <Col>
          <div className='text-end'>
            <Btn attrBtn={{ color: 'success', className: 'me-3', }}>Create Coupon</Btn>
            <Link to={`${process.env.PUBLIC_URL}/app/coupon/coupon-list`}>
              <Btn attrBtn={{ color: 'danger' }}>{Cancel}</Btn>
            </Link>
          </div>
        </Col>
      </Row>
      </Form>
    </Fragment>
  );
};

export default CouponForm;
