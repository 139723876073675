import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import "./style.css";
import paymentGatewayStore from "../../../../store/paymentGatewayStore";
// Function to get style based on status
const getStatusStyle = (status) => {
  switch (status) {
    case 'Successful':
      return { color: 'green', fontWeight: 'bold' };
    case 'Initiated':
      return { color: '#4A90E8', fontWeight: 'bold' };
    case 'Pending':
      return { color: 'grey', fontWeight: 'bold' };
    case 'SUCCESS':
      return { color: 'purple', fontWeight: 'bold' };
    case 'In Hub':
      return { color: 'navy', fontWeight: 'bold' };
    case 'Delivered':
      return { color: 'green', fontWeight: 'bold' };
    case 'Failed':
      return { color: 'red', fontWeight: 'bold' };
    case 'Refunded':
      return { color: '#26A5A9', fontWeight: 'bold' };
    case 'Returned':
      return { color: 'pink', fontWeight: 'bold' };
    default:
      return { fontWeight: 'bold' };
  }
};

const OrderHistoryTable = () => {
  const {
    fetchPaymentGatewayHistory,
    totalRows,
    allPaymentGatewayHistoryList,
  } = paymentGatewayStore(); // Assume `totalRows` is available from the store

  // State to keep track of current page
  const [currentPage, setCurrentPage] = useState(1);

  // State to keep track of number of rows per page
  const [dataCount, setDataCount] = useState(10);


  // Effect to fetch data when currentPage or dataCount changes
  useEffect(() => {
    fetchPaymentGatewayHistory();
  }, [fetchPaymentGatewayHistory]);

  // Handler for page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handler for rows per page change
  const handleCountChange = (newPerPage, page) => {
    setDataCount(newPerPage); // Update data count
    setCurrentPage(page); // Update current page
  };

  const updatedData = {
    productRow: allPaymentGatewayHistoryList
      ? allPaymentGatewayHistoryList.slice((currentPage-1)*dataCount,currentPage*dataCount).map((item) => ({
          orderID: <div className="product-name">{item?.order_id}</div>,
          transactionID: item?.transaction_id,
          paymentMethod: (
            <div className="payout-details">{item?.payment_method}</div>
          ),
          amount: (
            <div className="payout-notes" id={`payoutNotes-${item.id}`}>
              {"৳" + item?.trx_amount}
            </div>
          ),
          paymentStatus: <div style={getStatusStyle(item?.payment_status)}>{item?.payment_status}</div>,
          updatedOn: <div>{item?.updated_on.slice(1,19)}</div>,
        }))
      : [],
    productColumns: [
      {
        name: "Order ID",
        selector: (row) => row.orderID,
        sortable: true,
      },
      {
        name: "Transaction ID",
        selector: (row) => row.transactionID,
        sortable: true,
      },
      {
        name: "Payment Method",
        selector: (row) => row.paymentMethod,
        sortable: true,
      },
      {
        name: "Amount",
        selector: (row) => row.amount,
        sortable: true,
      },
      {
        name: "Payment Status",
        selector: (row) => row.paymentStatus,
        sortable: true,
      },
      {
        name: "Updated On",
        selector: (row) => row.updatedOn,
        sortable: true,
      },
    ],
  };

  return (
    <div className="table-responsive table-bordernone orderHistoryTable product-table">
      <DataTable
        pagination
        paginationServer
        paginationTotalRows={totalRows} // Ensure total number of rows is provided
        columns={updatedData.productColumns}
        data={updatedData.productRow}
        highlightOnHover={true}
        striped={true}
        responsive={true}
        onChangePage={handlePageChange} // Attach page change handler
        onChangeRowsPerPage={handleCountChange} // Attach rows per page change handler
      />
    </div>
  );
};

export default OrderHistoryTable;
