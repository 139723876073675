import { create } from "zustand";
import { getPaymentGatewayHistory, getPaymentGatewayLog } from "../Services/paymentGatewayService";

const withdrawalStore = create((set, get) => ({
  allPaymentGatewayHistoryList: [],
  allPaymentGatewayLogsList: [],
  totalRows: 0, // Added this to ensure totalRows is initialized
  errorMessage: "",

  // Fetch all payment gateway history
  fetchPaymentGatewayHistory: async () => {
    try {
      const response = await getPaymentGatewayHistory();
      if (response.code === 200) {
        if (response.data && response.data.length > 0) { // Check if results array is not empty
          set({
            allPaymentGatewayHistoryList: response.data,
            totalRows: response?.data?.length+1,
            errorMessage: "",
          });
        } else {
          set({
            allPaymentGatewayHistoryList: [],
            totalRows: 0,
            errorMessage: response.message,
          });
        }
      } else {
        set({
          errorMessage: response.message,
        });
      }
      // Removed the problematic log statement
    } catch (err) {
      console.error("Error while fetching withdrawal data", err);
      set({ errorMessage: "An error occurred while fetching filter data." });
      throw err;
    }
  },

  // Fetch all payment gateway logs
  fetchPaymentGatewayLog: async () => {
    try {
      const response = await getPaymentGatewayLog();
      if (response.code === 200) {
        if (response.data && response.data.length > 0) { // Check if results array is not empty
          set({
            allPaymentGatewayLogsList: response.data,
            totalRows: response?.data?.length+1,
            errorMessage: "",
          });
        } else {
          set({
            allPaymentGatewayLogsList: [],
            totalRows: 0,
            errorMessage: response.message,
          });
        }
      } else {
        set({
          errorMessage: response.message,
        });
      }
    } catch (err) {
      console.error("Error while fetching withdrawal data", err);
      set({ errorMessage: "An error occurred while fetching filter data." });
      throw err;
    }
  },
}));

export default withdrawalStore;
