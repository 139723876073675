// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

// //File Manager
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";
//Seller Order
import WindowSellerOrderList from '../Components/Application/SellerOrder'
import SellerOrderInvoice from '../Components/Application/SellerOrder/SellerOrderInvoice'
import MainOrderInvoice from '../Components/Application/SellerOrder/SellerOrderInvoice/MainOrderInvoice'
import FilterOrder from '../Components/Application/SellerOrder/OrderFilter'
// //E-commerce
import Products from "../Components/Application/Ecommerce/Products";
import Banner from "../Components/Application/Ecommerce/Banner"
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductCategory";

//business type
import WindowBusinessType from '../Components/Application/Ecommerce/WindowBusinessType'
import SellerFinance from "../Components/Application/seller finance";
import WindowWithDrawal from "../Components/Application/WithDrawal";

//seller Shipping Finance
import WindowSellerShippingFinance from '../Components/Application/SellerShippingFinance';

import WindowShippingWithDraw from '../Components/Application/ShippingWithdraw';
//payment gateway pg logs
import PgLogs from '../Components/Application/PaymentGatewayHistory/PGLog';
//payment gateway pg History
import PgHistory from '../Components/Application/PaymentGatewayHistory/PGHistory';

//Country
import Country from '../Components/Application/Ecommerce/Country/ContactApp'

//State
import State from '../Components/Application/Ecommerce/State/ContactApp'

//Area
import Area from '../Components/Application/Ecommerce/Area/ContactApp'

//payment Method
import PaymentMethod from '../Components/Application/PaymentMethod'

//Home ads
import HomeAds from '../Components/Application/Ecommerce/HomeAds'
import Dashboard from "../pages/dashboard/DashboardPage";
import CustomerPage from "../pages/customer/CustomerPage";
import BusinessOnboardListPage from "../pages/BusinessOnboardList/BusinessOnboardListPage";
import CouponListPage from "../pages/coupon/CouponList/CouponListPage";
import NewCouponPage from "../pages/coupon/NewCoupon/NewCouponPage";
import DealsSettingsPage from "../pages/dealsOfTheDay/DealsSettingsPage";
import ProductDealsPage from "../pages/dealsOfTheDay/ProductDealsPage";





export const routes = [
  //dashboard
  { path: `/dashboard/default/`, Component: <Dashboard /> },
  //Ecommerce
  //customer table
  { path: `/app/customer`, Component: <CustomerPage /> },
  //all business onboard
  { path: `/app/business`, Component: <BusinessOnboardListPage /> },

  // //Applicatiion
  { path: `/app/coupon/coupon-list/`, Component: <CouponListPage /> },
  { path: `/app/coupon/new-coupon/`, Component: <NewCouponPage /> },
  
  //Deals of the Day
  { path: `/app/deal-of-the-day/deals-product/`, Component: <ProductDealsPage /> },
  { path: `/app/deal-of-the-day/deals-setting/`, Component: <DealsSettingsPage /> },

  //Seller Order
  { path: `/app/seller/seller-order-list/`, Component: <WindowSellerOrderList /> },
  { path: `/app/seller/seller-order-list/:id`, Component: <SellerOrderInvoice /> },
  { path: `/app/seller/seller-order-list/Main-order/:id`, Component: <MainOrderInvoice /> },
  { path: `/app/seller/seller-order-list/order-filter`, Component: <FilterOrder /> },

  //WithDrawal
  { path: `/app/withdrawal`, Component: <WindowWithDrawal /> },

  //pAYMENT Method
  { path: `/app/payment-method`, Component: <PaymentMethod /> },

  // seller finance
  { path: `/app/seller-finance`, Component: <SellerFinance /> },

  //seller Shipping Finance
  { path: `/app/seller-shipping-finance`, Component: <WindowSellerShippingFinance /> },

  //shipping withdraw
  { path: `/app/shipping-withdraw`, Component: <WindowShippingWithDraw /> },

  //payment gateway logs
  { path: `/app/payment-gateway/logs`, Component: <PgLogs /> },
  //payment gateway History
  { path: `/app/payment-gateway/history`, Component: <PgHistory /> },
  //Home Ads
  { path: `/app/home-ads`, Component: <HomeAds /> },

  { path: `/app/users/profile/`, Component: <UsersProfileContain /> },
  { path: `/app/users/edit/`, Component: <UsersEditContain /> },
  { path: `/app/users/cards/`, Component: <UsersCardssContain /> },

  { path: `/app/file-manager/`, Component: <FileManagerContain /> },
  { path: `/app/search/`, Component: <SearchResultContain /> },
  { path: `/app/ecommerce/product/`, Component: <Products /> },
  { path: `/app/ecommerce/banner/`, Component: <Banner /> },
  { path: `/app/ecommerce/payment-details/`, Component: <PaymentDetails /> },
  { path: `/app/ecommerce/orderhistory/`, Component: <OrderHistory /> },
  { path: `/app/ecommerce/pricing/`, Component: <PricingMemberShip /> },
  { path: `/app/ecommerce/invoice/`, Component: <Invoice /> },
  //Ecommerce
  { path: `/app/ecommerce/product-category/`, Component: <ProductListContain /> },//fetch done
  { path: `/app/ecommerce/business-type/`, Component: <WindowBusinessType /> },
  { path: `/app/ecommerce/banner/`, Component: <Banner /> },
  //Country
  { path: `/app/ecommerce/country/`, Component: <Country /> },
  //State
  { path: `/app/ecommerce/state/`, Component: <State /> },
  //Area
  { path: `/app/ecommerce/area/`, Component: <Area /> },

];
