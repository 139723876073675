import React, { useEffect, useState } from "react";
import { Image } from "../../../AbstractElements";
import { useStore } from "zustand";
import ToggleBtn from "./toggleButton";
import { Media } from "reactstrap";
import { paymentMethodToggleStatus } from '../../../Services/paymentMethodService';
import paymentMethodStore from "../../../store/paymentMethodStore";

export const BusinessList = ({ setData, openEditModal }) => {
  const { allMethodList, fetchAllMethod, deleteMethod } = useStore(paymentMethodStore);
  const [toggleDelete, setToggleDelete] = useState(false);

  const toggleStatuses = async (id) => {
    try {
      await paymentMethodToggleStatus(id);
    } catch (err) {
      console.error("Error while toggling status:", err);
    }
  };

  const handleDelete = async (id) => {
    await deleteMethod(id);
    setToggleDelete((prev) => !prev);
  };

  const deleteButtonStyle = {
    backgroundColor: '#e74c3c', // Red color for Delete button
    color: '#fff', // White text color
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    marginLeft: '10px' // Margin between buttons
  };
  
  const editButtonStyle = {
    backgroundColor: '#3498db', // Blue color for Edit button
    color: '#fff', // White text color
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease'
  };

  const handleEdit = (business) => {
    openEditModal(business); // Use the passed function
  };

  useEffect(() => {
    fetchAllMethod();
  }, [fetchAllMethod]);

  useEffect(() => {
    if (allMethodList && allMethodList.length > 0) {
      setData(
        allMethodList.map((business) => ({
          name: (
            <Media className="d-flex">
              <Image
                attrImage={{
                  className: "rounded-circle img-50 me-3",
                  src: business.logo,
                  alt: "image",
                }}
              />
              <Media body className="align-self-center">
                <div>{business.name}</div>
              </Media>
            </Media>
          ),
          charge: (
            <span className="f-w-700 font-success">
              {business.charge}
            </span>
          ),
          status: (
            <ToggleBtn
              value={business.status}
              onToggle={() => toggleStatuses(business.id)}
            />
          ),
          edit: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button onClick={() => handleEdit(business)} style={editButtonStyle}>
                Edit
              </button>
            </div>
          ),
          delete: (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button onClick={() => handleDelete(business.id)} style={deleteButtonStyle}>
                Delete
              </button>
            </div>
          ),
        }))
      );
    }
  }, [allMethodList, setData]);

  return null;
};


// CustomerData.js


export const tableColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    center: true,
  },
  {
    name: "Payment Charge",
    selector: (row) => row.charge,
    sortable: true,
    center: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    center: true,
  },
  {
    name: "Edit",
    selector: (row) => row.edit,
    sortable: true,
    center: true,
  },
  {
    name: "Delete",
    selector: (row) => row.delete,
    sortable: true,
    center: true,
  },
];
