import { create } from "zustand";
import { editUserinfo, getUserInfo } from "../Services/userService";

const userStore = create((set, get) => ({
  user: [],
  msg: null,  // Initialize msg state
  updateUserData: {
    first_name: "",
    last_name: "",
    email: ""
  },

  // Function to fetch user data
  fetchUser: async () => {
    try {
      const response = await getUserInfo();
      set({
        user: response.data
      });
    } catch (err) {
      console.log("Error fetching user list:", err);
      throw err;
    }
  },

  // Function to update form field state
  updateUserField: (e) => {
    const { name, value } = e.target;

    set((state) => ({
      updateUserData: {
        ...state.updateUserData,
        [name]: value,
      },
    }));
  },

  // Function to update user info
  updateUser: async (data) => {
    try {
      const response = await editUserinfo(data);
      set({
        msg: response.message  // Store the response message
      });
    } catch (err) {
      set({
        msg: "Error while editing user"  // Set error message on failure
      });
      console.error("Error while editing user", err);
      throw err;
    }
  },

  // Function to manually set the message (useful for clearing it)
  setMessage: (message) => {
    set({ msg: message });
  },
}));

export default userStore;
