import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"
//Country Services
export const getAllCountryList = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.fetchAllCountry)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in country list:",err);
        throw err
    }
}
export const fetchCountryInfo = async(param) =>
{
    try{
        const response = await apiServices.get(apiEndpoints.fetchCountryInfo+param)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in country info:",err);
        throw err
    }
}


export const updateCountryInfo = async(param,data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.updateCountryInfo+param,data)

        return response.data
    }catch(err)
    {
        console.error("updating error in country info:",err);
        throw err
    }
}



export const createCountry = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.createCountry,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create country:",err)
        throw err
    }
}

export const deleteCountry = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.deleteCountry+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a country:",err);
        throw err
    }
}






//State Services
export const getAllStateList = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.fetchAllState)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in state list:",err);
        throw err
    }
}
export const fetchStateInfo = async(param) =>
{
    try{
        const response = await apiServices.get(apiEndpoints.fetchSingleState+param)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in state info:",err);
        throw err
    }
}


export const updateStateInfo = async(param,data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.updateStateInfo+param,data)

        return response.data
    }catch(err)
    {
        console.error("updating error in state info:",err);
        throw err
    }
}



export const createState = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.createState,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create state:",err)
        throw err
    }
}

export const deleteState = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.deleteState+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a state:",err);
        throw err
    }
}
export const fetchStateByCountry = async(param)=>
{
    try{
        const response = await apiServices.delete(apiEndpoints.fetchStateByCountry+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a state:",err);
        throw err
    }
}
//Area
export const getAllAreaList = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.fetchAllArea)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in state list:",err);
        throw err
    }
}
export const fetchAreaInfo = async(param) =>
{
    try{
        const response = await apiServices.get(apiEndpoints.fetchSingleArea+param)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in state info:",err);
        throw err
    }
}


export const updateAreaInfo = async(param,data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.updateAreaInfo+param,data)

        return response.data
    }catch(err)
    {
        console.error("updating error in state info:",err);
        throw err
    }
}



export const createArea = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.createArea,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create state:",err)
        throw err
    }
}

export const deleteArea = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.deleteArea+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a state:",err);
        throw err
    }
}
export const fetchAreaByState = async(param)=>
{
    try{
        const response = await apiServices.delete(apiEndpoints.fetchAreaByState+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a state:",err);
        throw err
    }
}


export default {
    getAllCountryList,
    fetchCountryInfo,
    updateCountryInfo,
    createCountry,
    deleteCountry,
    fetchStateByCountry,
    deleteState,
    createState,
    updateStateInfo,
    fetchStateInfo,
    getAllStateList,
    fetchAreaByState,deleteArea,createArea,updateAreaInfo,fetchAreaInfo,getAllAreaList
}
