import { create } from 'zustand';
import {
  getAllCoupon,
  getCouponInfo,
  createCoupon,
  deleteCoupon,
  toggleCouponStatus,
  updateCoupon
} from '../Services/couponService'; // Adjust the path as needed

const couponStore = create((set, get) => ({
  coupons: [],
  singleCoupon: null,
  message: null,
  couponData: {
    coupon_code: "",
    coupon_type: "",
    coupon_value: null,
    usage_limit: null,
    start_date: new Date(),
    end_date: new Date(),
    exclude_category: []
  },

  // Fetch all coupons
  fetchAllCoupons: async () => {
    try {
      const response = await getAllCoupon();
      set(() => ({
        coupons: response.data,
      }));
    } catch (err) {
      console.error("Error fetching all coupons:", err);
      throw err;
    }
  },

  // Update coupon field (without async)
  updateCouponField: (e) => {
  const { name, value } = e.target;
  set((state) => ({
    couponData: {
      ...state.couponData,
      [name]: name === 'start_date' || name === 'end_date' ? new Date(value) : value,
    },
  }));
},


  // Fetch single coupon info
  fetchCouponInfo: async (id) => {
    try {
      const response = await getCouponInfo(id);
      set(() => ({
        singleCoupon: response,
      }));
    } catch (err) {
      console.error("Error fetching coupon info:", err);
      throw err;
    }
  },
  clearMessage: () => {
    setTimeout(() => {
      set(() => ({
        message: null,
      }));
    }, 3000); // Hide the message after 3 seconds
  },

  // Create a new coupon
  createNewCoupon: async (data) => {
    try {
      const response = await createCoupon(data);
      set(() => ({
        message: response.message,
      }));
      // Optionally, refresh the coupon list after creation
      await get().fetchAllCoupons();
    } catch (err) {
      console.error("Error creating coupon:", err);
      throw err;
    }
  },

  // Update an existing coupon
  updateExistingCoupon: async (id, data) => {
    try {
      const response = await updateCoupon(id, data);
      set(() => ({
        message: response.message || "Coupon updated successfully",
      }));
      // Optionally, refresh the coupon list after updating
      await get().fetchAllCoupons();
    } catch (err) {
      console.error("Error updating coupon:", err);
      throw err;
    }
  },

  // Delete a coupon
  deleteCouponById: async (id) => {
    try {
      const response = await deleteCoupon(id);
      set(() => ({
        message: response.message || "Coupon deleted successfully",
      }));
      // Optionally, refresh the coupon list after deletion
      await get().fetchAllCoupons();
    } catch (err) {
      console.error("Error deleting coupon:", err);
      throw err;
    }
  },

  // Toggle coupon status
  toggleCouponStatusById: async (id) => {
    try {
      const response = await toggleCouponStatus(id);
      set(() => ({
        message: response.message || "Coupon status toggled successfully",
      }));
      // Optionally, refresh the coupon list after status toggle
      await get().fetchAllCoupons();
    } catch (err) {
      console.error("Error toggling coupon status:", err);
      throw err;
    }
  },
}));

export default couponStore;
