import React, { useEffect } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import CommonModal from "../../common/modal";
import { useStore } from "zustand";
import categoryStore from "../../../../../store/categoryStore";

const FormModal = ({ modal, NewMessage, toggle, defaultVal }) => {
  const { allCategoryList, fetchAllCategory, createCategory } =
    useStore(categoryStore);
  const store = categoryStore();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await createCategory();
      await fetchAllCategory();
      toggle();
    } catch (err) {
      console.error("Error while create category", err);
    }
  };
  useEffect(() => {
    fetchAllCategory();
    console.log("category:", fetchAllCategory);
  }, [fetchAllCategory]);
  return (
    <CommonModal
      isOpen={modal}
      title={"New Category"}
      toggler={toggle}
      onSubmit={handleSubmit}
    >
      <Form>
        <FormGroup>
          <Label className="col-form-label" for="category-name">
            {"Category Name:"}
          </Label>
          <Input
            onChange={store.updateCreateCategoryField}
            className="form-control"
            type="text"
            id="category-name"
            name="name"
            value={store.createNewCategoryData.name}
          />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label" for="icon">
            {"Icon:"}
          </Label>
          <Input
            onChange={store.updateCreateCategoryField}
            type="text"
            className="form-control"
            id="icon"
            name="icon"
            value={store.createNewCategoryData.icon}
          ></Input>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label" for="upload-image">
            {"Upload Image:"}
          </Label>
          <Input
            onChange={store.updateCreateCategoryField}
            type="file"
            className="form-control"
            id="upload-image"
            name="image"
            // value={store.createNewCategoryData.image}
          />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label" for="description">
            {"Description:"}
          </Label>
          <Input
            onChange={store.updateCreateCategoryField}
            type="text"
            className="form-control"
            id="description"
            name="description"
            value={store.createNewCategoryData.description}
          />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label" for="parent-category">
            {"Parent Category:"}
          </Label>
          <Input
            type="select"
            onChange={store.updateCreateCategoryField}
            className="form-control"
            id="parent-category"
            name="parent"
            value={store.createNewCategoryData.parent}
          >
            <option  selected>
              No Parent Category
            </option>
            {allCategoryList && allCategoryList.length > 0 ? (
              allCategoryList.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))
            ) : (
              <option  disabled>
                No categories available
              </option>
            )}
          </Input>
        </FormGroup>
      </Form>
    </CommonModal>
  );
};

export default FormModal;
