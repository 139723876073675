import React, { Component } from 'react';
import { H5, H6, P } from '../../../../AbstractElements';
class Printpreview extends Component {
  render() {
    const { selectedUser } = this.props
    return (
      <div className="profile-mail pt-0" id="DivIdToPrint">
        <div className="media">
          <div className="media-body mt-0">
            <H5><span id="printname">{"adad"} </span><span id="printlast">{"dfdf"}</span></H5>
            <p id="printmail">{'sdfs'}{"@gmail.com"}</p>
          </div>
        </div>
        <div className="email-general">
          <H6>{"General"}</H6>
          <P>{"Email Address"}: <span className="font-primary" id="mailadd">{"sdfs"}{"@gmail.com"}   </span></P>
        </div>
      </div>
    );
  }
}

export default Printpreview