import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, TabContent, TabPane } from 'reactstrap';

import { useStore } from 'zustand';
import { Breadcrumbs } from '../../../AbstractElements';
import CusClass from '../../../Components/Application/Project/Common/CusClass';
import couponStore from '../../../store/couponStore';

const CouponListPage = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { fetchAllCoupons, coupons } = useStore(couponStore);

  useEffect(() => {
    fetchAllCoupons();
  }, [fetchAllCoupons]);

  return (
    <Fragment>
      <Breadcrumbs parent="Coupon" title="Coupon List" mainTitle="Coupon List" />
      <Container fluid={true}>
        <Row className="project-card">
          <Col md="12" className="project-list">
            <Card>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      {coupons.map((item, i) => (
                        <CusClass item={item} key={i} />
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      {coupons
                        .filter(item => item.status) // Example filter for active coupons
                        .map((item, i) => (
                          <CusClass item={item} key={i} />
                        ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      {coupons
                        .filter(item => !item.status) // Example filter for inactive coupons
                        .map((item, i) => (
                          <CusClass item={item} key={i} />
                        ))}
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};


export default CouponListPage;