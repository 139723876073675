import { create } from "zustand";
import { getShippingFinanceList,getShippingFinanceListByShop} from "../Services/shippingFinanceService.js";


const withdrawalStore = create((set, get) => ({
  updatePageSizeData: 1,
  updatePageCountData: 10,
  updateBusinessIdData: 0,
  totalRows: 0,
  errorMessage:"",
  allShippingList: [],
  summary:{},
  
  

  //Fetch all shipping finance Filter list 
  fetchAllShippingFinanceList: async () => {
    
    try {
      const { updatePageCountData, updatePageSizeData } = get();
      const response = await getShippingFinanceList(updatePageCountData,updatePageSizeData);
      
      if (response.code === 200) {
        if (response.results && response.results.length > 0) { // Check if results array is not empty
          set({
            allShippingList: response.results,
            totalRows: response.count,
            summary:response.summary,
            errorMessage: "",
          });
        } else {
          set({
            allShippingList: [],
            totalRows: 0,
            summary:{},
            errorMessage: response.message,
          });
        }
      } else {
        set({
          errorMessage: response.message,
        });
      }
    } catch (err) {
      console.error("Error while fetching shipping data", err);
      set({ errorMessage: "An error occurred while fetching filter data." });
      throw err;
    }
  },

  
 

//Fetch all order List
  fetchAllShippingListByShop: async () => {
    try {
      const { updatePageSizeData, updatePageCountData, updateBusinessIdData } = get();
      const response = await getShippingFinanceListByShop(updateBusinessIdData, updatePageCountData, updatePageSizeData);
      if (response.code === 200) {
        set({
            allShippingList: response.results,
          totalRows: response.count,
          summary:response.summary,
          errorMessage: "",
        });
      } else {
        set({
          errorMessage: response.message,
        });
      }
    } catch (err) {
      console.log("Error while fetching withdrawal list:", err);
      set({ errorMessage: "An error occurred while fetching filter data." });
      throw err;
    }
  },
  //pagination for filter data
  updateFilterPaginationData: (count, pageSize) => {
    const {updateBusinessIdData} = get();
    if(updateBusinessIdData===null)
        {
            set({
                updatePageSizeData: count,
                updatePageCountData: pageSize,
            });
            get().fetchAllShippingFinanceList();
        }
        else{
            set({
                updatePageSizeData: count,
                updatePageCountData: pageSize,
            });
            get().fetchAllShippingListByShop();
        }
    
  },
  
  updateBusinessId: (businessId) => {
    if(businessId===null)
        {
            set({ updateBusinessIdData: businessId });
        get().fetchAllShippingFinanceList();
        }
        else{
            set({ updateBusinessIdData: businessId });
        get().fetchAllShippingListByShop();
        }
    
  },
  
}));

export default withdrawalStore;
