import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../../AbstractElements';
import orderStore from '../../../store/orderStore';
import shippingStore from '../../../store/shippingFinanceStore.js';
import ImageModal from './ImageModal';  // Import the modal component



const OrderHistoryTable = () => {
  const { allShippingList, updateFilterPaginationData, totalRows } = shippingStore(); // Assume `totalRows` is available from the store

  // State to keep track of current page
  const [currentPage, setCurrentPage] = useState(1);

  // State to keep track of number of rows per page
  const [dataCount, setDataCount] = useState(10);

  // State to manage modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  // Effect to fetch data when currentPage or dataCount changes
  useEffect(() => {
    updateFilterPaginationData(currentPage, dataCount);
  }, [updateFilterPaginationData, currentPage, dataCount]);

  // Handler for page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handler for rows per page change
  const handleCountChange = (newPerPage, page) => {
    setDataCount(newPerPage); // Update data count
    setCurrentPage(page); // Update current page
    updateFilterPaginationData(page, newPerPage); // Fetch new data based on updated page and count
  };

  // Handler to toggle modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const updatedData = {
    productRow: allShippingList ? allShippingList.map((item) => ({
      orderId: (
        <div className='product-name'>
          {item?.order_id}
        </div>
      ),
      shippingCharge: "৳" + item?.shipping_charge,
      shippingMethod: (
        <div>
          <p><strong>Method :</strong> {item?.shipping_info?.preview}</p>
          <p><strong>Charge :</strong> {item?.shipping_info?.charge}</p>
        </div>
      ),
      shippingDetails: (
        <div>
          <button onClick={() => { setModalImage(item?.seller_shipping_image); toggleModal(); }}>View</button>
        </div>
      ),
    })) : [],
    productColumns: [
      {
        name: 'Order ID',
        selector: (row) => row.orderId,
        sortable: true,
        center: true,
        minWidth: '150px',
        maxWidth: '200px',
      },
      {
        name: 'Shipping Charge',
        selector: (row) => row.shippingCharge,
        sortable: true,
        center: true,
        minWidth: '100px',
        maxWidth: '250px',
      },
      {
        name: 'Shipping Method',
        selector: (row) => row.shippingMethod,
        sortable: true,
        center: true,
        minWidth: '200px',
        maxWidth: '400px',
      },
      {
        name: 'Shipping Details',
        selector: (row) => row.shippingDetails,
        sortable: true,
        center: true,
        minWidth: '120px',
        maxWidth: '180px',
      },
    ],
  };

  return (
    <div className='table-responsive table-bordernone orderHistoryTable product-table' >
      <DataTable
        pagination
        paginationServer
        paginationTotalRows={totalRows} // Ensure total number of rows is provided
        columns={updatedData.productColumns}
        data={updatedData.productRow}
        highlightOnHover={true}
        striped={true}
        responsive={true}
        onChangePage={handlePageChange} // Attach page change handler
        onChangeRowsPerPage={handleCountChange} // Attach rows per page change handler
      />
      <ImageModal isOpen={modalOpen} toggle={toggleModal} imageUrl={modalImage} /> {/* Include the modal */}
    </div>
  );
};

export default OrderHistoryTable;
