import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getAllCustomer = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getCustomerList)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in customer list:",err);
        throw err
    }
}

export default getAllCustomer