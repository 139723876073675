import React, { useEffect } from "react";
import { Form, FormGroup, Input, Label, Alert } from "reactstrap";
import { useStore } from "zustand";
import businessStore from "../../store/businessStore";
import offerStore from "../../store/OfferStore";
import ReusableModal from "../../Components/Common/ReusableModal/ReusableModal";

const MakeDealForm = () => {
    const {
        fetchAllBusiness,
        businessList,
        individualBusinessField,
        updateField,
        allProduct,
        fetchProductByBusinessId,
        onSubmitOffer,
        products
    } = useStore(businessStore);

    const {
        createNewDealData,
        updateCreateOfferField,
        createOffer,
        fetchAllOfferList,
        errorMessage,
    } = useStore(offerStore);

    useEffect(() => {
        fetchAllBusiness();
    }, [fetchAllBusiness]);

    useEffect(() => {
        if (individualBusinessField?.parent) {
            fetchProductByBusinessId(individualBusinessField.parent);
        }
    }, [individualBusinessField?.parent, fetchProductByBusinessId]);

    const handleBusinessChange = (e) => {
        updateField(e);
        const selectedBusinessId = e.target.value;
        fetchProductByBusinessId(selectedBusinessId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createOffer();
            await fetchAllOfferList();
        } catch (err) {
            console.error("Error while creating offer", err);
        }
    };

    return (
        <ReusableModal
            modalTitle="New Product Offer"
            modalBtnText="Make Offer"
            onSubmit={(closeModal) => {
                handleSubmit();
            }}
            modalContent={<Form>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <FormGroup>
                    <Label className="col-form-label" for="Business-Name">
                        {"Category"}
                    </Label>
                    <Input
                        type="select"
                        onChange={handleBusinessChange} // Updated onChange handler
                        className="form-control"
                        id="Business-name"
                        name="parent"
                        value={individualBusinessField?.parent || ""} // Ensure the correct value is set
                    >
                        <option value="" disabled>
                            Select Business
                        </option>
                        {businessList && businessList.length > 0 ? (
                            businessList.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.business_name}
                                </option>
                            ))
                        ) : (
                            <option disabled>Not available</option>
                        )}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label className="col-form-label" for="Product">
                        {"Product:"}
                    </Label>
                    <Input
                        type="select"
                        onChange={updateCreateOfferField}
                        className="form-control"
                        id="product"
                        name="product"
                        value={createNewDealData.product || ""}
                    >
                        <option value="" disabled>
                            Select Product
                        </option>
                        {products && products.length > 0 ? (
                            products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))
                        ) : (
                            <option disabled>Not available</option>
                        )}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label className="col-form-label" for="sale-price">
                        {"Sale Price"}
                    </Label>
                    <Input
                        onChange={updateCreateOfferField}
                        className="form-control"
                        type="text"
                        id="sale-price"
                        name="sale_price"
                        value={createNewDealData.sale_price || ""}
                    />
                </FormGroup>
            </Form>}
        />
    );
};

export default MakeDealForm;