import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"
//withdrawal list without shop
export const getShippingFinanceList = async(count,page) =>
{
    try{
        const response = await apiServices.get(apiEndpoints.sellerShippingFinance+"?count="+count+"&page="+page);
        return response.data;
    }catch(err)
    {
        console.error("error while get finance list",err)
        throw err
    }
}
//Withdrawal list with Shop ID
export const getShippingFinanceListByShop = async(id,count,page) =>
    {
        try{
            const response = await apiServices.get(apiEndpoints.sellerShippingFinanceByShop+id+"?count="+count+"&page="+page);
            return response.data;
        }catch(err)
        {
            console.error("error while get finance list",err)
            throw err
        }
    }
//order Status changing

export default {getShippingFinanceList,getShippingFinanceListByShop}