import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { OrderHistoryDataTable } from './OrderColumn';
import orderStore from '../../../../store/orderStore';

// Function to get style based on status
const getStatusStyle = (status) => {
  switch (status) {
    case 'Pending':
      return { color: 'orange', fontWeight: 'bold' };
    case 'Processing':
      return { color: '#4A90E8', fontWeight: 'bold' };
    case 'On Hold':
      return { color: 'grey', fontWeight: 'bold' };
    case 'Shipped':
      return { color: 'purple', fontWeight: 'bold' };
    case 'In Hub':
      return { color: 'navy', fontWeight: 'bold' };
    case 'Delivered':
      return { color: 'green', fontWeight: 'bold' };
    case 'Cancelled':
      return { color: 'red', fontWeight: 'bold' };
    case 'Refunded':
      return { color: '#26A5A9', fontWeight: 'bold' };
    case 'Returned':
      return { color: 'pink', fontWeight: 'bold' };
    default:
      return { fontWeight: 'bold' };
  }
};

// Function to get background style based on payment status
const getPaymentStatusStyle = (status) => {
  switch (status) {
    case 'Fully Paid':
      return { backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '4px' };
    case 'Partially Paid':
      return { backgroundColor: '#7DDA58', color: 'white', padding: '5px', borderRadius: '4px' };
    case 'DUE':
      return { backgroundColor: 'orange', color: 'white', padding: '5px', borderRadius: '4px' };
      case 'Cancelled':
      return { backgroundColor: '#A05455', color: 'white', padding: '5px', borderRadius: '4px' };
      case 'Failed':
      return { backgroundColor: '#D20103', color: 'white', padding: '5px', borderRadius: '4px' };
      
    default:
      return {};
  }
};

const OrderHistoryTable = () => {
  const { filterOrderList, totalFilterDataRows,updateFilterPaginationData } = orderStore(); // Assume `totalRows` is available from the store

  // State to keep track of current page
  const [currentPage, setCurrentPage] = useState(1);
  
  // State to keep track of number of rows per page
  const [dataCount, setDataCount] = useState(10);

  // Effect to fetch data when currentPage or dataCount changes
  useEffect(() => {
    updateFilterPaginationData(currentPage, dataCount);
   
  }, [updateFilterPaginationData, currentPage, dataCount]);

  // Handler for page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handler for rows per page change
  const handleCountChange = (newPerPage, page) => {
    setDataCount(newPerPage); // Update data count
    setCurrentPage(page); // Update current page
    updateFilterPaginationData(page, newPerPage); // Fetch new data based on updated page and count
  };

  const updatedData = {
    productRow: filterOrderList ? filterOrderList.map((item) => ({
      mainOrderId: (
        <div className='product-name'>
          {item.parent_order_info.parent_order_id}
        </div>
      ),
      orderId: item.order_id,
      totalAmount: "৳"+item.total,
      orderStatus: (
        <div style={getStatusStyle(item.order_status)}>
          {item.order_status}
        </div>
      ),
      mainOrderStatus: (
        <div style={getStatusStyle(item.parent_order_info.order_status)}>
          {item.parent_order_info.order_status}
        </div>
      ),
      paymentStatus: (
        <div style={getPaymentStatusStyle(item.parent_order_info.payment_status)}>
          {item.parent_order_info.payment_status}
        </div>
      ),
      orderDate: item.create_at,
      action: (<Link to={`/app/seller/seller-order-list/${item.order_id}`}>
        <ExternalLink />
      </Link>),
    })) : [],
    productColumns: [
      ...OrderHistoryDataTable.productColumns,
      {
        name: <i className='fa fa-cogs' />,
        selector: (row) => row.action,
        sortable: false,
        center: true,
        minWidth: '50px',
        maxWidth: '60px',
      },
    ],
  };

  return (
    <div className='table-responsive table-bordernone orderHistoryTable product-table'>
      <DataTable
        pagination
        paginationServer
        paginationTotalRows={totalFilterDataRows} // Ensure total number of rows is provided
        columns={updatedData.productColumns}
        data={updatedData.productRow}
        highlightOnHover={true}
        striped={true}
        responsive={true}
        onChangePage={handlePageChange} // Attach page change handler
        onChangeRowsPerPage={handleCountChange} // Attach rows per page change handler
      />
    </div>
  );
};

export default OrderHistoryTable;
