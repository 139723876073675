import { useEffect } from "react";

import { useStore } from "zustand";
import businessTypeStore from "../../../../store/businessTypeStore";
import { Media } from "reactstrap";
import { Btn } from "../../../../AbstractElements";

// const style = {
//   width: 80,
//   height: 80,
// };
const style = {
  width: 60, fontSize: 14, padding: 4
};
// const style2 = { width: 60, fontSize: 14, padding: 4 };

export const CustomerList = ({ setData }) => {
  const { allBusinessTypeList, fetchAllBusinessType, toggleEditType,deleteType } = useStore(businessTypeStore);
 const handleDelete=async(id)=>
 {
  await deleteType(id);
  await fetchAllBusinessType();
 } 
  useEffect(() => {
    // Fetch categories on mount
    const fetchCustomers = async () => {
      await fetchAllBusinessType();
    };
    fetchCustomers();
  }, [fetchAllBusinessType]);

  useEffect(() => {
    //console.log("List:",allBusinessTypeList)
   
    // Set data when categories change
    if (allBusinessTypeList && allBusinessTypeList.length > 0) {
      setData(
        allBusinessTypeList.map((type) => ({
          name: (
            <Media className="d-flex">
              <Media body className="align-self-center">
                <div>{type.business_type}</div>
              </Media>
            </Media>
          ),
          action: (
            <div>
              <span>
                <Btn attrBtn={{ style: style, color: 'danger', className: 'btn btn-xs', type: 'button', onClick:()=> handleDelete(type.id) }}>Delete</Btn>
              </span>
              &nbsp;&nbsp;
              <span>
                <Btn  attrBtn={{ style: style, color: 'success', className: 'btn btn-xs', type: 'button', onClick:()=>toggleEditType(type) }}>Edit</Btn>
              </span>
            </div>
          ),
        }))
      );
    }
  }, [allBusinessTypeList, setData]);

  return null;
};

export const  tableColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    center: false,
  },
  {
    name: 'Action',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  },
  
];

