import { create } from "zustand";
import {
  getAllCountryList,
  fetchCountryInfo,
  updateCountryInfo,
  createCountry,
  deleteCountry,
  fetchStateByCountry,
  deleteState,
  createState,
  updateStateInfo,
  fetchStateInfo,
  getAllStateList,
  fetchAreaByState,
  deleteArea,
  createArea,
  updateAreaInfo,
  fetchAreaInfo,
  getAllAreaList,
} from "../Services/regionServices";

const RegionStore = create((set, get) => ({
  // Country
  createNewCountry: { name: "" },
  updateCountryData: { name: "" },
  countryInfo: {},
  id: null,
  allCountryList: [],
  modal: false,

  createCountry: async () => {
    try {
      const { createNewCountry } = get();
      const response = await createCountry(createNewCountry);
      set((state) => ({
        allCountryList: [...state.allCountryList, response.data],
        createNewCountry: { name: "" },
      }));
    } catch (error) {
      console.log("Error while creating Country:", error);
      throw error;
    }
  },

  updateCreateCountryField: (e) => {
    const { name, value } = e.target;
    set((state) => ({
      createNewCountry: {
        ...state.createNewCountry,
        [name]: value,
      },
    }));
  },

  fetchAllCountryList: async () => {
    try {
      const response = await getAllCountryList();
      set({ allCountryList: response.data });
    } catch (err) {
      console.error("Error fetching Country list:", err);
    }
  },

  fetchSingleCountryInfo: async (id) => {
    try {
      const response = await fetchCountryInfo(id);
      set({ countryInfo: response.data });
    } catch (err) {
      console.error("Error fetching Single Country Info:", err);
    }
  },

  editCountry: async (id, data) => {
    try {
      const response = await updateCountryInfo(id, data);
      set((state) => ({
        countryInfo: response.data,
        updateCountryData: { name: "" },
        id: id,
        modal: false,
      }));
    } catch (err) {
      console.error("Error while editing country:", err);
      throw err;
    }
  },

  setUpdateCountryData: (countryData) => {
    set(() => ({
      updateCountryData: countryData,
      modal: true,
    }));
  },

  closeEditModal: () => {
    set(() => ({
      modal: false,
      updateCountryData: { name: "" },
    }));
  },

  setID: (data) => {
    set(() => ({
      id: data.id,
    }));
  },

  deleteCountry: async (id) => {
    try {
      await deleteCountry(id);
      set((state) => ({
        allCountryList: state.allCountryList.filter(
          (country) => country.id !== id
        ),
      }));
    } catch (err) {
      console.error("Error while deleting country:", err);
    }
  },

  // State
  createNewState: { country: null, name: "" },
  updateStateData: { country: null, name: "" },
  StateInfo: {},
  StateId: null,
  allStateList: [],
  modal: false,

  createState: async () => {
    try {
      const { createNewState } = get();
      const response = await createState(createNewState);
      set((state) => ({
        allStateList: [...state.allStateList, response.data],
        createNewState: { country: null, name: "" },
      }));
    } catch (error) {
      console.log("Error while creating State:", error);
      throw error;
    }
  },

  updateCreateStateField: (e) => {
    const { name, value } = e.target;
    set((state) => ({
      createNewState: {
        ...state.createNewState,
        [name]: value,
      },
    }));
  },

  fetchAllStateList: async () => {
    try {
      const response = await getAllStateList();
      set({ allStateList: response.data });
    } catch (err) {
      console.error("Error fetching State list:", err);
    }
  },

  fetchSingleStateInfo: async (id) => {
    try {
      const response = await fetchStateInfo(id);
      set({ StateInfo: response.data });
    } catch (err) {
      console.error("Error fetching Single State Info:", err);
    }
  },

  editState: async (id, data) => {
    try {
      const response = await updateStateInfo(id, data);
      set((state) => ({
        StateInfo: response.data,
        updateStateData: { country: null, name: "" },
        StateId: id,
        modal: false,
      }));
    } catch (err) {
      console.error("Error while editing State:", err);
      throw err;
    }
  },

  setUpdateStateData: (countryData) => {
    set(() => ({
      updateStateData: countryData,
      modal: true,
    }));
  },

  closeEditModal: () => {
    set(() => ({
      modal: false,
      updateStateData: { country: null, name: "" },
    }));
  },

  setStateID: (data) => {
    set(() => ({
      StateId: data.id,
    }));
  },

  deleteState: async (id) => {
    try {
      await deleteState(id);
      set((state) => ({
        allStateList: state.allStateList.filter((state) => state.id !== id),
      }));
    } catch (err) {
      console.error("Error while deleting State:", err);
    }
  },
   // Area
   createNewArea: { state: null, name: "" },
   updateAreaData: { state: null, name: "" },
   AreaInfo: {},
   AreaId: null,
   allAreaList: [],
   modal: false,
 
   createArea: async () => {
     try {
       const { createNewArea } = get();
       const response = await createArea(createNewArea);
       set((state) => ({
         allAreaList: [...state.allAreaList, response.data],
         createNewArea: { state: null, name: "" },
       }));
     } catch (error) {
       console.log("Error while creating State:", error);
       throw error;
     }
   },
 
   updateCreateAreaField: (e) => {
     const { name, value } = e.target;
     set((state) => ({
       createNewArea: {
         ...state.createNewArea,
         [name]: value,
       },
     }));
   },
 
   fetchAllAreaList: async () => {
     try {
       const response = await getAllAreaList();
       set({ allAreaList: response.data });
     } catch (err) {
       console.error("Error fetching State list:", err);
     }
   },
 
   fetchSingleAreaInfo: async (id) => {
     try {
       const response = await fetchAreaInfo(id);
       set({ AreaInfo: response.data });
     } catch (err) {
       console.error("Error fetching Single State Info:", err);
     }
   },
 
   editArea: async (id, data) => {
     try {
       const response = await updateAreaInfo(id, data);
       set((state) => ({
        AreaInfo: response.data,
         updateAreaData: { state: null, name: "" },
         AreaId: id,
         modal: false,
       }));
     } catch (err) {
       console.error("Error while editing State:", err);
       throw err;
     }
   },
 
   setUpdateAreaData: (countryData) => {
     set(() => ({
       updateAreaData: countryData,
       modal: true,
     }));
   },
 
   closeEditModal: () => {
     set(() => ({
       modal: false,
       updateAreaData: { state: null, name: "" },
     }));
   },
 
   setAreaID: (data) => {
     set(() => ({
      AreaId: data.id,
     }));
   },
 
   deleteArea: async (id) => {
     try {
       await deleteArea(id);
       set((state) => ({
         allAreaList: state.allAreaList.filter((state) => state.id !== id),
       }));
     } catch (err) {
       console.error("Error while deleting State:", err);
     }
   },
}));

export default RegionStore;
