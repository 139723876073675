import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import './style.css';
import paymentGatewayStore from '../../../../store/paymentGatewayStore';

// Function to get style based on status
const getStatusStyle = (status) => {
  switch (status) {
    case 'Successful':
      return { color: 'green', fontWeight: 'bold' };
    case 'Initiated':
      return { color: '#4A90E8', fontWeight: 'bold' };
    case 'Pending':
      return { color: 'grey', fontWeight: 'bold' };
    case 'SUCCESS':
      return { color: 'purple', fontWeight: 'bold' };
    case 'In Hub':
      return { color: 'navy', fontWeight: 'bold' };
    case 'Delivered':
      return { color: 'green', fontWeight: 'bold' };
    case 'Failed':
      return { color: 'red', fontWeight: 'bold' };
    case 'Refunded':
      return { color: '#26A5A9', fontWeight: 'bold' };
    case 'Returned':
      return { color: 'pink', fontWeight: 'bold' };
    default:
      return { fontWeight: 'bold' };
  }
};

const OrderHistoryTable = () => {
  const { fetchPaymentGatewayLog, allPaymentGatewayLogsList,totalRows } = paymentGatewayStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [dataCount, setDataCount] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (fetchPaymentGatewayLog) {
      fetchPaymentGatewayLog().then(fetchedData => {
        setData(fetchedData);
      });
    } else {
      console.error('fetchPaymentGatewayLog is not a function');
    }
  }, [fetchPaymentGatewayLog]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCountChange = (newPerPage, page) => {
    setDataCount(newPerPage);
    setCurrentPage(page);
  };

  const updatedData = {
    productRow: allPaymentGatewayLogsList ? allPaymentGatewayLogsList.slice((currentPage - 1) * dataCount, currentPage * dataCount).map((item) => ({
      orderID: <div className="product-name">{item?.order_id}</div>,
      session: item?.session_key,
      gWStatus: (
        <div className="payout-details" style={getStatusStyle(item?.gw_status)}>
          {item?.gw_status}
        </div>
      ),
      failedReason: (
        <div className="payout-notes" id={`payoutNotes-${item?.id}`}>
          {(item?.failed_reason ? item?.failed_reason : "Empty")}
        </div>
      ),
      status: <div style={getStatusStyle(item?.status)}>{item?.status}</div>,
      createdOn: <div>{item?.created_on.slice(0,19)}</div>,
    })):[],
    productColumns: [
      {
        name: "Order ID",
        selector: (row) => row.orderID,
        sortable: true,
      },
      {
        name: "Session",
        selector: (row) => row.session,
        sortable: true,
        center: false,
        width:'220px'
      },
      {
        name: "GW Status",
        selector: (row) => row.gWStatus,
        sortable: true,
        width:'150px'
      },
      {
        name: "Failed Reason",
        selector: (row) => row.failedReason,
        sortable: true,
        width:'220px'
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        width:'100px'
      },
      {
        name: "Created On",
        selector: (row) => row.createdOn,
        sortable: true,
        width:'220px'
      },
    ],
  };

  return (
    <div className="table-responsive table-bordernone orderHistoryTable product-table">
      <DataTable
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        columns={updatedData.productColumns}
        data={updatedData.productRow}
        highlightOnHover={true}
        striped={true}
        responsive={true}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleCountChange}
      />
    </div>
  );
};

export default OrderHistoryTable;
