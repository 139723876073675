import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import HeaderCard from "../../Common/Component/HeaderCard";
import businessStore from "../../../store/businessStore";
import withdrawalStore from "../../../store/withdrawalStore";
const DropDown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { businessList, fetchAllBusiness } = businessStore();
  const { updateBusinessId } = withdrawalStore();
  const [selectBtnName,setSelectBtnName] = useState("Select Store")
  const withdrawStatus =false;
  useEffect(() => {
    fetchAllBusiness();
  }, [fetchAllBusiness]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSelect = (businessId,name)=>
  {
    updateBusinessId(businessId);
    setSelectBtnName(name)
    setDropdownOpen(false);
    
  }

  return (
    <Col sm="12" xl="12">
      <Card>
        <HeaderCard title={"Select Store Name"} />
        <CardBody>
          <div className="dropdown-basic">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="dropdown"
             
            >
              <DropdownToggle tag="div" className="dropbtn">
                <Btn attrBtn={{ color: "primary", className: "dropbtn" }}>
                  {selectBtnName}
                  <span>
                    <i className="icofont icofont-arrow-down"></i>
                  </span>
                </Btn>
              </DropdownToggle>
              <DropdownMenu>
              <DropdownItem onClick={() => handleSelect(null, "All")}>
                  All
                </DropdownItem>
                {businessList && businessList.length > 0 ? (
                  businessList.map((business) => (
                    <DropdownItem key={business.id} value={business.id} onClick={()=>handleSelect(business.id,business.business_name)}>
                      {business.business_name}
                    </DropdownItem>
                  ))
                ) : (
                  <DropdownItem disabled>No businesses available</DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DropDown;