
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Tooltip } from 'reactstrap';
// import { ExternalLink } from 'react-feather';
// import { Link } from 'react-router-dom';
// import { Image } from '../../../AbstractElements';
// import { OrderHistoryDataTable } from './OrderColumn';
import './style.css'
import withdrawalStore from '../../../store/withdrawalStore';
// Function to get style based on status
const getStatusStyle = (status) => {
  switch (status) {
    case 'Pending':
      return { color: 'orange', fontWeight: 'bold' };
    case 'Processing':
      return { color: '#4A90E8', fontWeight: 'bold' };
    case 'On Hold':
      return { color: 'grey', fontWeight: 'bold' };
    case 'Shipped':
      return { color: 'purple', fontWeight: 'bold' };
    case 'In Hub':
      return { color: 'navy', fontWeight: 'bold' };
    case 'Delivered':
      return { color: 'green', fontWeight: 'bold' };
    case 'Cancelled':
      return { color: 'red', fontWeight: 'bold' };
    case 'Refunded':
      return { color: '#26A5A9', fontWeight: 'bold' };
    case 'Returned':
      return { color: 'pink', fontWeight: 'bold' };
    default:
      return { fontWeight: 'bold' };
  }
};


const OrderHistoryTable = () => {
  const { allWithdrawalList, updateFilterPaginationData, totalRows } = withdrawalStore(); // Assume `totalRows` is available from the store

  // State to keep track of current page
  const [currentPage, setCurrentPage] = useState(1);
  
  // State to keep track of number of rows per page
  const [dataCount, setDataCount] = useState(10);

  // Tooltip states
  const [tooltipOpen, setTooltipOpen] = useState({});
  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Effect to fetch data when currentPage or dataCount changes
  useEffect(() => {
    updateFilterPaginationData(currentPage, dataCount);
  }, [updateFilterPaginationData, currentPage, dataCount]);

  // Handler for page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handler for rows per page change
  const handleCountChange = (newPerPage, page) => {
    setDataCount(newPerPage); // Update data count
    setCurrentPage(page); // Update current page
    updateFilterPaginationData(page, newPerPage); // Fetch new data based on updated page and count
  };

  const updatedData = {
    productRow: allWithdrawalList ? allWithdrawalList.map((item) => ({
      Seller: (
        <div className='product-name'>
          {item.business}
        </div>
      ),
      PayoutMethod: item.payout_method,
      PayoutDetails: (
        <div className="payout-details" id={`payoutDetails-${item.id}`}>
          {item.payout_details}
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[`payoutDetails-${item.id}`]}
            target={`payoutDetails-${item.id}`}
            toggle={() => toggleTooltip(`payoutDetails-${item.id}`)}
          >
            {item.payout_details}
          </Tooltip>
        </div>
      ),
      PayoutNotes: (
        <div className="payout-notes" id={`payoutNotes-${item.id}`}>
          {item.notes}
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[`payoutNotes-${item.id}`]}
            target={`payoutNotes-${item.id}`}
            toggle={() => toggleTooltip(`payoutNotes-${item.id}`)}
          >
            {item.notes}
          </Tooltip>
        </div>
      ),
      RequestAmount: (
        <div>
          {"৳" + item.req_amount}
        </div>
      ),
      CurrentBalance: (
        <div>
          {"৳" + item.new_balance}
        </div>
      ),
      RequestStatus: (
        <div style={getStatusStyle(item.req_status)}>
          {item.req_status}
        </div>
      ),
      RequestDate: new Date(item.created_at).toLocaleString(),
    })) : [],
    productColumns: [
      {
        name: 'Seller',
        selector: row => row.Seller,
        sortable: true,
      },
      {
        name: 'Payout Method',
        selector: row => row.PayoutMethod,
        sortable: true,
      },
      {
        name: 'Payout Details',
        selector: row => row.PayoutDetails,
        sortable: true,
      },
      {
        name: 'Payout Notes',
        selector: row => row.PayoutNotes,
        sortable: true,
      },
      {
        name: 'Request Amount',
        selector: row => row.RequestAmount,
        sortable: true,
      },
      {
        name: 'Current Balance',
        selector: row => row.CurrentBalance,
        sortable: true,
      },
      {
        name: 'Request Status',
        selector: row => row.RequestStatus,
        sortable: true,
      },
      {
        name: 'Request Date',
        selector: row => row.RequestDate,
        sortable: true,
      },
      {
        name: 'Action',
        selector: row => row.action,
        sortable: false,
        center: true,
        minWidth: '50px',
        maxWidth: '60px',
      },
    ],
  };

  return (
    <div className='table-responsive table-bordernone orderHistoryTable product-table'>
      <DataTable
        pagination
        paginationServer
        paginationTotalRows={totalRows} // Ensure total number of rows is provided
        columns={updatedData.productColumns}
        data={updatedData.productRow}
        highlightOnHover={true}
        striped={true}
        responsive={true}
        onChangePage={handlePageChange} // Attach page change handler
        onChangeRowsPerPage={handleCountChange} // Attach rows per page change handler
      />
    </div>
  );
};

export default OrderHistoryTable;