import { create } from "zustand";
import { getAllPaymentMethodList, createPaymentMethodType, editPaymentMethod, deletePaymentMethod } from '../Services/paymentMethodService';

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const paymentMethodStore = create((set, get) => ({
  createNewMethod: {
    name: "",
    charge: "",
    status: true,
    logo: "",
  },
  id: null,
  allMethodList: [],

  createMethod: async () => {
    try {
      const { createNewMethod } = get();
      const response = await createPaymentMethodType(createNewMethod);
      set((state) => ({
        allMethodList: [...state.allMethodList, response.data],
        createNewMethod: {
          name: "",
          charge: "",
          status: true,
          logo: "",
        },
      }));
    } catch (error) {
      console.log("Error while creating payment method:", error);
      throw error;
    }
  },

  updateCreateMethodField: async (e) => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const base64 = await fileToBase64(file);
      set((state) => ({
        createNewMethod: {
          ...state.createNewMethod,
          [name]: base64,
        },
      }));
    } else {
      set((state) => ({
        createNewMethod: {
          ...state.createNewMethod,
          [name]: value,
        },
      }));
    }
  },

  fetchAllMethod: async () => {
    try {
      const response = await getAllPaymentMethodList();
      set({ allMethodList: response.data });
    } catch (err) {
      console.error("Error fetching payment method list:", err);
    }
  },

  deleteMethod: async (id) => {
    try {
      await deletePaymentMethod(id);
      set((state) => ({
        allMethodList: state.allMethodList.filter((method) => method.id !== id),
      }));
    } catch (err) {
      console.error("Error while deleting payment method:", err);
    }
  },

  editMethod: async (id, updatedMethod) => {
    try {
      const response = await editPaymentMethod(id, updatedMethod);
      set((state) => ({
        allMethodList: state.allMethodList.map((method) =>
          method.id === id ? response.data : method
        ),
      }));
    } catch (err) {
      console.error("Error while editing payment method:", err);
    }
  },
}));

export default paymentMethodStore;
