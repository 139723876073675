import React, { Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import FooterCard from "../../../Forms/FormControl/Common/FooterCard";
import orderStore from "../../../../store/orderStore";

const BasicFormControlClass = () => {
  const {
    NewFilterOrderData,
    UpdateFilterOrderField,
    fetchOrderFilter,
    errorMessage,
  } = orderStore();
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchOrderFilter();
  };
  return (
    <Fragment>
      <Card>
        <HeaderCard title={"Filter Form"} />
        <Form className="form theme-form" onSubmit={handleSubmit}>
          <CardBody>
            {errorMessage && (
              <Row>
                <Col>
                  <Alert color="danger">{errorMessage}</Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="exampleFormControlInput1">{"Order ID"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    onChange={UpdateFilterOrderField}
                    placeholder="APD459250"
                    name="order_id"
                    value={NewFilterOrderData.order_id}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="exampleInputPassword2">
                    {"Transaction ID"}
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Transaction ID"
                    onChange={UpdateFilterOrderField}
                    name="trx_id"
                    value={NewFilterOrderData.trx_id}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect9">
                    {"Payment Status"}
                  </Label>
                  <Input
                    type="select"
                    name="payment_status"
                    className="form-control digits"
                    defaultValue="select"
                    onChange={UpdateFilterOrderField}
                    value={NewFilterOrderData.payment_status}
                  >
                    <option selected>No selected</option>
                    <option value="Fully Paid">{"Fully Paid"}</option>
                    <option value="Partially Paid">{"Partially Paid"}</option>
                    <option value="DUE">{"DUE"}</option>
                    <option value="Cancelled">{"Cancelled"}</option>
                    <option value="Failed">{"Failed"}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect3">
                    {"Main Order Status"}
                  </Label>
                  <Input
                    type="select"
                    name="parent_order_status"
                    className="form-control digits"
                    defaultValue="select"
                    onChange={UpdateFilterOrderField}
                    value={NewFilterOrderData.parent_order_status}
                  >
                    <option selected>No selected</option>
                    <option value="Pending">Pending</option>
                    <option value="Processing">Processing</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Shipped">Shipped</option>
                    <option value="In Hub">In Hub</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Refunded">Refunded</option>
                    <option value="Returned">Returned</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="exampleFormControlSelect3">
                    {"Selling Order Status"}
                  </Label>
                  <Input
                    type="select"
                    name="child_order_status"
                    className="form-control digits"
                    defaultValue="select"
                    onChange={UpdateFilterOrderField}
                    value={NewFilterOrderData.child_order_status}
                  >
                    <option selected>No selected</option>
                    <option value="Pending">Pending</option>
                    <option value="Processing">Processing</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Shipped">Shipped</option>
                    <option value="In Hub">In Hub</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Refunded">Refunded</option>
                    <option value="Returned">Returned</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <FooterCard footerClass="text-end" />
        </Form>
      </Card>
    </Fragment>
  );
};

export default BasicFormControlClass;
