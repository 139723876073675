import { Breadcrumbs } from '../../../../AbstractElements';
import ProductGrid1 from './ProductGrid1';
import ProductGrid2 from './ProductGrid2';
import ProductGrid3 from './ProductGrid3';
import React from 'react';
import { Fragment } from 'react';
import { Container } from 'reactstrap';
import { useState } from 'react';
import Modals from './Modals'
import Modals1 from './Modals1'
import Modals2 from './Modals2'


const ProductContain = () => {
    const [modal, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modal);
    };
    return (
        <Fragment>
            <Breadcrumbs parent="Home Ads" title="Home Ads" mainTitle="Home Ads" />
            <Container fluid={true} className="product-wrapper" id="product-wrapper">
                <div className="product-grid">
                <Modals isOpen={modal} toggle={handleModal} />
                    <ProductGrid1 />
                </div>
            </Container>
            <hr/>
            <Container fluid={true} className="product-wrapper" id="product-wrapper">
                <div className="product-grid">
                <Modals1 isOpen={modal} toggle={handleModal} />
                    <ProductGrid2 />
                </div>
            </Container>
            <hr/>
            <Container fluid={true} className="product-wrapper" id="product-wrapper">
                <div className="product-grid">
                <Modals2 isOpen={modal} toggle={handleModal} />
                    <ProductGrid3 />
                </div>
            </Container>
        </Fragment>
    );
};
export default ProductContain;