import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const allCategory = async() =>
{
    try{
        const response = await apiServices.get(apiEndpoints.allCategory)

        return response.data
    }catch(err)
    {
        console.error("Fetching error in allCategoryList:",err);
        throw err
    }
}

export const createCategory = async(data) =>
{
    try{
        const response  =  await apiServices.post(apiEndpoints.create_category,data)
        return response.data
    }
    catch(err)
    {
        console.log("error in create category:",err)
        throw err
    }
}

export const deleteCategory = async(param) =>
{
    try{
        const response = await apiServices.delete(apiEndpoints.delete_category+param);
        return response;
    }catch(err)
    {
        console.error("Error while delete a category:",err);
        throw err
    }
}

export const editCategory = async(param,data) =>
{
    try{
        const response = await apiServices.post(apiEndpoints.edit_category+param,data)
        return response.data
    }catch(err)
    {
        console.error("Error while editing category:",err);
        throw err
    }
}


export default {
    allCategory,
    createCategory,
    deleteCategory,
    editCategory
}
