import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { tableColumns } from "./CustomerData";
import { CustomerList } from "./CustomerData";
import SearchBar from "../../CommonElements/SearchCustomer/searchCustomer";
import { useStore } from "zustand";
import customerStore from "../../store/customerStore";

const DataTableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const { filteredList, list, updateFilteredList } = useStore(customerStore);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  //filter search Darta
  useEffect(() => {
    let newList = list.filter((user) => {
      const userName = user.name;
      return (
        userName.includes(search?.toLowerCase()) ||
        user.email?.toLowerCase().includes(search?.toLowerCase()) ||
        user.mobile_number?.toLowerCase().includes(search?.toLowerCase())
      );
    });
    updateFilteredList(newList);
  }, [search]);

  return (
    <Fragment>
      <SearchBar onSearch={setSearch} />
      <CustomerList setData={setData} />
      <DataTable
        data={data}
        columns={tableColumns}
        striped
        center
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelete}
      />
    </Fragment>
  );
};

export default DataTableComponent;
