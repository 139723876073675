import React, { Fragment, useState } from 'react';
import { NewContacts, AddContacts, Name, Save, Cancel } from '../../../../../Constant';
import defaultuser from '../../../../../assets/images/user/user.png';
import { Btn, Image } from '../../../../../AbstractElements';
import { Users } from 'react-feather';
import { Row, Col, Modal, ModalHeader, ModalBody, Label, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useStore } from 'zustand';
import regionStore from '../../../../../store/regionStore';

const CreateContact = () => {
  const [modal, setModal] = useState(false);
  const [addurl, setAddurl] = useState(defaultuser);
  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { createCountry, updateCreateCountryField, createNewCountry } = useStore(regionStore);

  const AddContact = async (data) => {
    if (data !== '') {
      await createCountry();
      setModal(false);
    } else {
      errors.showMessages();
    }
  };

  const HandleAddUrl = (event) => {
    if (event.target.files.length === 0) return;

    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) return;

    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setAddurl(reader.result);
    };
  };

  return (
    <Fragment>
      <Btn
        attrBtn={{
          className: `badge-light-primary align-items-center btn-mail d-flex justify-content-start w-100 emptyContact`,
          color: 'primery',
          onClick: toggle,
        }}
      >
        <Users className='me-2' />
        Create Country
      </Btn>
      <Modal className='modal-bookmark' isOpen={modal} toggle={toggle} size='lg'>
        <ModalHeader toggle={toggle}>Add Country</ModalHeader>
        <ModalBody>
          <Form className='form-bookmark needs-validation' onSubmit={handleSubmit(AddContact)}>
            <FormGroup className='col-md-12'>
              <Label>{Name}</Label>
              <Row>
                <Col sm='6'>
                  <input
                    className='form-control'
                    name='name'
                    type='text'
                    {...register('name', { required: true })}
                    onChange={(e) => updateCreateCountryField(e)}
                  />
                  <span style={{ color: 'red' }}>{errors.name && 'Country name is required'}</span>
                </Col>
              </Row>
            </FormGroup>
            <Btn attrBtn={{ color: 'secondary', className: 'me-2' }}>{Save}</Btn>
            <Btn attrBtn={{ color: 'primary', onClick: toggle }}>{Cancel}</Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CreateContact;
