import React, { useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import HeaderCard from "../../Common/Component/HeaderCard";
import businessStore from "../../../store/businessStore";
import Select from 'react-select';
import shippingStore from "../../../store/shippingFinanceStore";

const DropDown = () => {
  const { businessList, fetchAllBusiness } = businessStore();
  const { updateBusinessId } = shippingStore();
  const [selectBusiness, setSelectBusiness] = useState("Select Business");

  useEffect(() => {
    fetchAllBusiness();
  }, [fetchAllBusiness]);

  const handleSelect = (selectedOption) => {
    const businessId = selectedOption ? selectedOption.id : null;
    const businessName = selectedOption ? selectedOption.business_name : "All";
    updateBusinessId(businessId);
    setSelectBusiness(selectedOption);
  };

  // Add "All" option to the business list
  const options = [
    { id: null, business_name: "All" },
    ...businessList
  ];

  return (
    <Col sm="12" xl="12">
      <Card>
        <HeaderCard title={"Select Business to Get Data"} />
        <CardBody>
          <Select
            getOptionLabel={(value) => value?.business_name}
            getOptionValue={(value) => value?.id}
            isSearchable={true}
            value={selectBusiness}
            onChange={handleSelect}
            options={options}
            placeholder="Select Business"
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default DropDown;
