import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { tableColumns } from "./offerData";
import { CustomerList } from "./offerData";
import { useStore } from "zustand";
import BusinessTypeStore from "../../store/businessTypeStore";
import EditBusinessTypeModal from "../../Components/Application/Ecommerce/WindowBusinessType/BusinessTypeModal/common/editBusinessTypeModal";

const DataTableComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const { filteredList, allBusinessTypeList } = useStore(BusinessTypeStore);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  return (
    <Fragment>
      <CustomerList setData={setData} />
      <DataTable
        data={data}
        columns={tableColumns}
        striped
        center
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelete}
      />
      <EditBusinessTypeModal/>
    </Fragment>
  );
};

export default DataTableComponent;
