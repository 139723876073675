import { useEffect } from "react";
import { Image } from "../../AbstractElements";
import { useStore } from "zustand";
import customerStore from "../../store/customerStore";
import { Media } from "reactstrap";

// const style = {
//   width: 80,
//   height: 80,
// };

// const style2 = { width: 60, fontSize: 14, padding: 4 };

export const CustomerList = ({ setData }) => {
  const { filteredList, fetchAllCustomer } = useStore(customerStore);

  useEffect(() => {
    // Fetch categories on mount
    const fetchCustomers = async () => {
      await fetchAllCustomer();
    };
    fetchCustomers();
  }, [fetchAllCustomer]);

  useEffect(() => {
    // Set data when categories change
    if (filteredList && filteredList.length > 0) {
      setData(
        filteredList.map((customer) => ({
          name: (
            <Media className="d-flex">
              <Image
                attrImage={{
                  className: "rounded-circle img-50 me-3",
                  src: customer.photo_url,
                  alt: "Generic placeholder image",
                }}
              />
              <Media body className="align-self-center">
                <div>{customer.name}</div>
              </Media>
            </Media>
          ),
          mobile_number: (
            <span className="f-w-700 font-success">
              {customer.mobile_number}
            </span>
          ),
          email: (
            <span className="badge badge-light-primary">
              {customer.email_address}
            </span>
          ),
        }))
      );
    }
  }, [filteredList, setData]);

  return null;
};

export const  tableColumns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    center: false,
  },
  {
    name: "email",
    selector: (row) => row.email,
    sortable: true,
    center: true,
  },
  {
    name: "Mobile Number",
    selector: (row) => row.mobile_number,
    sortable: true,
    center: true,
  },
  
];

