import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col
} from "reactstrap";
import HeaderCard from "../../Common/Component/HeaderCard";
import businessStore from "../../../store/businessStore";
import Select from 'react-select'
import orderStore from "../../../store/orderStore";

const DropDown = () => {
  const { businessList, fetchAllBusiness } = businessStore();
  const { updateBusinessId } = orderStore();
  const [selectBusiness, setSelectBusiness] = useState("Select Business")

  useEffect(() => {
    fetchAllBusiness();
  }, [fetchAllBusiness]);


  const handleSelect = (business) => {
    updateBusinessId(business?.id);
    setSelectBusiness(business);
  }

  return (
    <Col sm="12" xl="12">
      <Card>
        <HeaderCard title={"Select Business Name"} />
        <CardBody>
          <Select
            getOptionLabel={(value) => value?.business_name}
            getOptionValue={(value) => value?.id}
            isSearchable={true}
            value={selectBusiness}
            onChange={handleSelect}
            options={businessList} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default DropDown;