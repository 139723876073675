import apiEndpoints from "./apiEndpoints";
import apiServices from "./apiServices"

export const getSellerOrderById = async(id,size,page) =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getShopOrderById+id+"?count="+size+"&page="+page);
        return response.data;
    }catch(err)
    {
        console.error("error while get seller orderBy ID",err)
        throw err
    }
}
//Order Invoice list 
export const getInvoiceFromOrder = async(id) =>
{
    try{
        const response = await apiServices.get(apiEndpoints.getSellerOrderInvoice+id);
        return response.data;
    }catch(err){
        console.error("Error while fetching invoice from order:",err)
        throw err;
    }
}
//order Status changing
export const editOrderStatus = async(id,status)=>
{
    try{
        const response = await apiServices.post(apiEndpoints.editStatusOrder+id+"/"+status);
        return response.message;
    }catch(err)
    {
        console.log("error while change status",err);
        throw err;
    }
}

//Order Filtering 
export const fetchAllFilterOrderList = async(size,page,data)=>
{
    try{
        const response = await apiServices.post(apiEndpoints.filterOrder+"?count="+size+"&page="+page,data);
        return response.data;
    }catch(err){
        console.log("error while filter Order:",err);
        throw err   
    }
}
export const getAllOrder = async(i) =>
    {
        try{
            const response = await apiServices.get(apiEndpoints.getOrderList);
            return response.data;
        }catch(err){
            console.error("Error while fetching invoice from order:",err)
            throw err;
        }
    }

export default {getSellerOrderById,getInvoiceFromOrder,editOrderStatus,fetchAllFilterOrderList}