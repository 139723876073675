import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import { useStore } from 'zustand';
import businessStore from '../../store/businessStore';
import Table from '../../CommonElements/Table/Table';
import { tableColumns } from './components/columns';
import { Breadcrumbs } from '../../AbstractElements';
import useBusiness from './hooks/useBusiness';


const BusinessOnboardListPage = () => {
  const { data } = useBusiness()
  return (
    <Fragment>
      <Breadcrumbs parent="Table" title="On Board Business" mainTitle="On Board Business" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="On Boarded Business List" />
              <CardBody>
                <Table
                  data={data}
                  columns={tableColumns}
                />
                {/* <DataTableComponent/> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default BusinessOnboardListPage;