import { create } from "zustand"
import { login } from "../Services/authService"
import tokenService from "../Services/tokenService";
import apiEndpoints from "../Services/apiEndpoints";
const authStore = create((set) =>
({
    user_group: null,
    user: null,
    token: localStorage.getItem('token') || null,
    isLoading: false,
    errorMessage: null,
    loggedIn: null,
    loginForm: {
        email: "",
        password: ""
    },
    updateLoginForm: (e) => {
        //take the name and value from where user hit and type
        const { name, value } = e.target;

        //set it
        set((state) => {
            return {
                loginForm: {
                    ...state.loginForm,
                    [name]: value
                }
            };
        })
    },

    login: async () => {
        set({ isLoading: true, error: null });
        const { loginForm } = authStore.getState()
        try {
            const response = await login(loginForm);
            tokenService.setToken(apiEndpoints.accessTokenKey, response.data.access_token);
            tokenService.setToken(apiEndpoints.refreshToken, response.data.refresh_token)
            set({ user: response.user_object, user_group: response.user_group, token: response.token, isLoading: false, loggedIn: true });
            //console.log("user:",response.user_object)
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Login error';

            set({ errorMessage: errorMessage, isLoading: false, loggedIn: false })

        }
    }
}))

export default authStore;