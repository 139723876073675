import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label, Alert } from 'reactstrap';
import { useStore } from 'zustand';
import userStore from '../../../store/userStore';

const UserEditModal = ({ isOpen, toggle }) => {
  const { updateUserData, updateUserField, updateUser, fetchUser, msg, setMessage } = useStore(userStore);

  const saveHandler = async () => {
    const { first_name, last_name, email } = updateUserData;
    const payload = { first_name, last_name, email };

    try {
      await updateUser(payload);  // Save changes (e.g., sync with backend)
      await fetchUser();  // Fetch the updated user data after saving
    } catch (error) {
      console.error('Error updating or fetching user:', error);
    }

    toggle();  // Close the modal
  };

  useEffect(() => {
    if (msg) {
      // Automatically clear the message after a few seconds
      const timer = setTimeout(() => {
        setMessage(null);  // Clear the message
      }, 3000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [msg, setMessage]);

  return (
    <>
      {/* Alert outside the modal */}
      {msg && (
        <Alert color={msg === 'Error while editing user' ? 'danger' : 'success'}>
          {msg}
        </Alert>
      )}

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit User Information</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              type="text"
              id="firstName"
              name="first_name"
              value={updateUserData.first_name}
              onChange={updateUserField}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              type="text"
              id="lastName"
              name="last_name"
              value={updateUserData.last_name}
              onChange={updateUserField}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={updateUserData.email}
              onChange={updateUserField}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveHandler}>
            Save
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UserEditModal;
