import { useEffect, useState } from "react";
import { useStore } from "zustand";
import businessStore from "../../../store/businessStore";

const useBusiness = () => {
    const [data, setData] = useState([]);
    const { fetchAllBusiness, filteredBusinessList } = useStore(businessStore);

    useEffect(() => {
        const fetchBusiness = async () => {
            await fetchAllBusiness();
        };
        fetchBusiness();
    }, [fetchAllBusiness]);

    useEffect(() => {
        if (filteredBusinessList && filteredBusinessList.length > 0) {
            setData(filteredBusinessList);
        }
    }, [filteredBusinessList]);
    
    return { data }
};

export default useBusiness;