import React, { Fragment, useEffect, useState } from "react";
import { useStore } from "zustand";
import { Link } from "react-router-dom";
import { Row, Card, Button } from "reactstrap";
import ProductModal from "./ProductModal";
import { H4, LI, P, Image, UL } from "../../../../AbstractElements";
import productStore from "../../../../store/productStore"; // Adjust the path as needed
import ProductRating from "./ProductRating";
import { FaToggleOn, FaToggleOff, FaMoneyBillAlt } from "react-icons/fa"; // Import icons

const ProductGrid = () => {
  const { fetchAllProducts, products, toggleProductStatus, toggleAdvancePayStatus, productStatus, advanceStatus } = useStore(productStore);
  const layoutColumns = 3;

  const [dataid, setDataid] = useState(""); // State for the selected product ID
  const [openModal, setOpenModal] = useState(false); // State for opening the modal

  // Fetch all products when the component mounts
  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  // Function to handle modal opening
  const onOpenModal = (productId) => {
    setOpenModal(true);
    setDataid(productId); // Set the selected product ID
  };

  // Function to return the image URL dynamically
  const dynamicImage = (image) => {
    return image.startsWith("http") ? image : require(`../../../../assets/images/${image}`);
  };

  // Function to handle toggling product status
  const handleToggleProductStatus = (id) => {
    toggleProductStatus(id);
  };

  // Function to handle toggling advance pay status
  const handleToggleAdvanceStatus = (id) => {
    toggleAdvancePayStatus(id);
  };

  return (
    <Fragment>
      <div className="product-wrapper-grid" id="product-wrapper-grid">
        <Row className="gridRow" id="gridRow">
          {products &&
            products.map((item, index) => {
              // Check if productStatus and advanceStatus are defined before accessing
              const isProductStatusTrue = productStatus && productStatus[index];
              const isAdvanceStatusTrue = advanceStatus && advanceStatus[index];

              return (
                <div
                  id="gridId"
                  className={`${layoutColumns === 3 ? "col-xl-3 col-lg-6 col-sm-6 xl-4 box-col-4" : "col-xl-" + layoutColumns}`}
                  key={item.id}
                >
                  <Card>
                    <div className="product-box">
                      <div className="product-img">
                        {item.on_sale && <span className="ribbon ribbon-danger">on sale</span>}
                        {item.featured && (
                          <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                            <i className="icon-heart">featured</i>
                          </span>
                        )}
                        <Image attrImage={{ className: "img-fluid", src: dynamicImage(item.image1), alt: "" }} />
                        <div className="product-hover">
                          <UL attrUL={{ className: "simple-list d-flex flex-row" }}>
                            <LI attrLI={{ className: "border-0" }}>
                              <Button color="default" data-toggle="modal" onClick={() => onOpenModal(item.id)}>
                                <i className="icon-eye"></i>
                              </Button>
                            </LI>
                          </UL>
                        </div>
                      </div>
                      <div className="product-details">
                        <div className="rating">
                          <ProductRating rating={item.average_rating} />
                        </div>
                        <Link to={`${process.env.PUBLIC_URL}/app/ecommerce/product-page/${item.id}`}>
                          <H4>{item.name}</H4>
                        </Link>
                        <P>{item.short_description}</P>
                        <div className="product-price">
                          ৳{item.sale_price}{" "}
                          <del>৳ {item.regular_price}</del>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                        <Button
  color={isProductStatusTrue ? "success" : "danger"}
  onClick={() => handleToggleProductStatus(item.id)}
  title="Toggle Product Status"
>
  {isProductStatusTrue ? <FaToggleOn /> : <FaToggleOff />}
</Button>
<Button
  color={isAdvanceStatusTrue ? "success" : "danger"}
  onClick={() => handleToggleAdvanceStatus(item.id)}
  title="Toggle Advance Payment Status"
>
  <FaMoneyBillAlt />
</Button>

                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          {openModal && <ProductModal value={openModal} setOpenModal={setOpenModal} dataid={dataid} />}
        </Row>
      </div>
    </Fragment>
  );
};

export default ProductGrid;
