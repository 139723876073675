import { create } from "zustand";
import { allCategory, createCategory, deleteCategory, editCategory } from "../Services/productCategoryService";

// Helper function to convert file to Base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// Helper function to convert URL to Base64
const urlToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => fileToBase64(blob))
      .then(base64 => resolve(base64))
      .catch(err => reject(err));
  });
};

const categoryStore = create((set) => ({
  createNewCategoryData: {
    name: "",
    parent: 0,
    icon: "",
    image: "",
    description: "",
  },
  id: null,
  updateCategoryData: {
    name: "",
    parent: 0,
    icon: "",
    image: "",
    description: "",
  },

  allCategoryList: [],
  modal: false,

  // Create a new category
  createCategory: async () => {
    try {
      const { createNewCategoryData } = categoryStore.getState();
      const response = await createCategory(createNewCategoryData);
      set((state) => ({
        allCategoryList: [...state.allCategoryList, response.data],
        createNewCategoryData: {
          name: "",
          parent: 0,
          icon: "",
          image: "",
          description: "",
        },
      }));
    } catch (error) {
      console.log("Error while creating category:", error);
      throw error;
    }
  },

  updateCreateCategoryField: async (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const base64 = await fileToBase64(file);
      set((state) => ({
        createNewCategoryData: {
          ...state.createNewCategoryData,
          [name]: base64,
        },
      }));
    } else {
      set((state) => ({
        createNewCategoryData: {
          ...state.createNewCategoryData,
          [name]: value,
        },
      }));
    }
  },

  fetchAllCategory: async () => {
    try {
      const response = await allCategory();
      set((state) => ({
        allCategoryList: response.data,
      }));
    } catch (err) {
      console.error("Error fetching category list:", err);
    }
  },

  // Toggle edit category modal and set category data
  toggleEditCategory: async (category) => {
    const { name, parent, icon, image, id, description } = category;
    //need to convert in base64 again for edit request
    let imageBase64 = "";
    if (image) {
      try {
        imageBase64 = await urlToBase64(image);
      } catch (error) {
        console.error("Error converting image URL to Base64:", error);
      }
    }

    set((state) => ({
      updateCategoryData: {
        name,
        parent: parent?.id || 0,
        icon,
        image: imageBase64,
        description,
      },
      id,
      modal: true,
    }));
  },

  // Close the edit modal
  closeEditModal: () => {
    set((state) => ({
      modal: false,
      updateCategoryData: {
        id: null,
        name: "",
        parent: 0,
        icon: "",
        image: "",
        description: "",
      },
    }));
  },

  updateEditCategoryField: async (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      const file = files[0];
      const base64 = await fileToBase64(file);
      set((state) => ({
        updateCategoryData: {
          ...state.updateCategoryData,
          [name]: base64,
        },
      }));
    } else {
      set((state) => ({
        updateCategoryData: {
          ...state.updateCategoryData,
          [name]: value,
        },
      }));
    }
  },

  editCategoryList: async () => {
    try {
      const { updateCategoryData, allCategoryList, id } = categoryStore.getState();
      const response = await editCategory(id, updateCategoryData);
      const newCategory = [...allCategoryList];
      const categoryIndex = allCategoryList.findIndex((data) => {
        return data.id === id;
      });
      newCategory[categoryIndex] = response.data;
      set((state) => ({
        allCategoryList: newCategory,
        updateCategoryData: {
          name: "",
          parent: 0,
          icon: "",
          image: "",
          description: "",
        },
        id: null,
        modal: false,
      }));
    } catch (err) {
      console.error("Error while editing category:", err);
      throw err;
    }
  },

  deleteCategory: async (id) => {
    try {
      await deleteCategory(id);
      set((state) => ({
        allCategoryList: state.allCategoryList.filter((category) => category.id !== id),
      }));
    } catch (err) {
      console.error("Error while deleting category:", err);
    }
  },
}));

export default categoryStore;
