import React, { useEffect } from 'react';
import { Btn, H6, Image } from '../../AbstractElements';
import { useStore } from 'zustand';
import categoryStore from '../../store/categoryStore';
import product1 from '../../assets/images/ecommerce/product-table-1.png';

const style = {
  width: 80,
  height: 80,
};

const style2 = { width: 60, fontSize: 14, padding: 4 };

const ProductList = ({ setData }) => {
  const { allCategoryList, fetchAllCategory, deleteCategory, toggleEditCategory } = useStore(categoryStore);

  useEffect(() => {
    // Fetch categories on mount
    const fetchCategories = async () => {
      await fetchAllCategory();
    };
    fetchCategories();
  }, [fetchAllCategory]);

 
  useEffect(() => {
    // Set data when categories change
    if (allCategoryList && allCategoryList.length > 0) {
      setData(
        allCategoryList.map((product) => ({
          id: product.id,
          image: <Image attrImage={{ src: product.image || product1, style: style, alt: product.name }} />,
          Details: (
            <div>
              <H6>{product.name}</H6>
              {/* <span>{product.description}</span> */}
            </div>
          ),
          parent:product.parent.name,
          name: product.name,
          description: product.description,
          icon: product.icon,
          action: (
            <div>
              <span>
                <Btn attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button', onClick:()=> deleteCategory(product.id) }}>Delete</Btn>
              </span>
              &nbsp;&nbsp;
              <span>
                <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button', onClick:()=>toggleEditCategory(product) }}>Edit</Btn>
              </span>
            </div>
          ),
        }))
      );
    }
  }, [allCategoryList, setData]);

  return null;
};

const productColumns = [
  {
    name: 'Image',
    selector: (row) => row.image,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  {
    name: 'Name',
    selector: (row) => row.Details,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '400px',
  },
  {
    name: 'Parent Category',
    selector: (row) => row.parent,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '400px',
  },
  {
    name: 'Description',
    selector: (row) => row.description,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '150px',
  },
  {
    name: 'Icon',
    selector: (row) => row.icon,
    sortable: true,
    center: true,
    minWidth: '120px',
    maxWidth: '150px',
  },
  {
    name: 'Action',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  },
];

export default ProductList;
export { productColumns };
