import { create } from "zustand";
import {
  getAllBusinessList,
  getProductByBusiness,
} from "../Services/businessService";

const businessStore = create((set) => ({
  businessList: [],
  filteredBusinessList: [],
  products: [],
  selectedBusinessId: null,
  individualBusinessField: {}, // Add this field to hold form data

  fetchAllBusiness: async () => {
    try {
      const response = await getAllBusinessList();
      set({
        businessList: response.data,
        filteredBusinessList: response.data,
      });
    } catch (err) {
      console.log("Error fetching business list:", err);
      throw err;
    }
  },

  fetchProductByBusinessId: async (id) => {
    try {
      const response = await getProductByBusiness(id);
      set({
        products: response.data,
        selectedBusinessId: id,
      });
    } catch (err) {
      console.error("Error fetching product:", err);
      throw err;
    }
  },

  filterBusinessListFunction: (keyword) => {
    set((state) => ({
      filteredBusinessList: state.businessList.filter((business) =>
        business?.name?.toLowerCase()?.includes(keyword.toLowerCase())
      ),
    }));
  },

  updateField: (e) => {
    const { name, value } = e.target;
    set((state) => ({
      individualBusinessField: {
        ...state.individualBusinessField,
        [name]: value,
      },
    }));
  },
}));

export default businessStore;
