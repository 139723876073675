import React, { Fragment, useEffect } from 'react';
import PrintComponent from './Print';
import { Breadcrumbs } from '../../../../../AbstractElements';
import orderStore from '../../../../../store/orderStore';
import { useParams } from 'react-router';

const InvoiceContain = () => {

  const { id } = useParams();
  const { fetchInvoiceFromOrderId, updatedOrderIdInvoiceList } = orderStore();
  useEffect(() => {
    fetchInvoiceFromOrderId(id);
  }, [fetchInvoiceFromOrderId, id]);

  return (
    <Fragment>
      <Breadcrumbs parent='Ecommerce' title='Details' mainTitle='Details' />
      <PrintComponent data={updatedOrderIdInvoiceList}/>
    </Fragment>
  );
};
export default InvoiceContain;
