import { useEffect } from "react";

import { useStore } from "zustand";
import offerStore from "../../store/OfferStore";
import { Media } from "reactstrap";
import { Btn,Image } from "../../AbstractElements";
import product1 from '../../assets/images/ecommerce/product-table-1.png';
import { toggleOfferStatus } from "../../Services/offerService";
import ToggleBtn from "../../CommonElements/toggleButton";
// import product1 from '../../../../as'



const style2 = { width: 60, fontSize: 14, padding: 4 };
// const style = {
//   width: 80,
//   height: 80,
// };
const style = {
  width: 60, fontSize: 14, padding: 4
};
// const style2 = { width: 60, fontSize: 14, padding: 4 };

export const CustomerList = ({ setData }) => {
  const { fetchAllOfferList,allOfferList,deleteOfferProduct } = useStore(offerStore);
//  const handleDelete=async(id)=>
//  {
//   await deleteType(id);
//   await fetchAllBusinessType();
//  } 
  useEffect(() => {
    // Fetch categories on mount
    
     fetchAllOfferList();
    
  }, [fetchAllOfferList]);

  const toggleStatus= async(data) =>
  {
    try{
    await toggleOfferStatus(data);
      

    }catch(err)
    {
      console.error("error while toggling status: ",err)
    }
  }

  const handleDelete = async(id)=>
  {
    try{
      console.log("hi")
      await deleteOfferProduct(id)
    }catch(err)
    {
      console.error("error while delete offer",err);
      throw err
    }
    
  }

  useEffect(() => {
    // Set data when categories change
    if (allOfferList && allOfferList.length > 0) {
      setData(
        allOfferList.map((type) => ({
          image: <Image attrImage={{ src: type?.product?.image1 || product1, style: style, alt: type.name }} />,
          name: (
            <Media className="d-flex">
              <Media body className="align-self-center">
                <div>{type?.product?.name}</div>
              </Media>
            </Media>
          ),
          regularPrice: (
            <Media className="d-flex">
              <Media body className="align-self-center">
                <div>{type?.product?.regular_price}</div>
              </Media>
            </Media>
          ),
          salePrice: (
            <Media className="d-flex">
              <Media body className="align-self-center">
                <div>{type?.product?.sale_price}</div>
              </Media>
            </Media>
          ),
          offerStatus:(
            <ToggleBtn value={type.status}
              onToggle={()=>toggleStatus(type.id)}
              />
            
          ),
          action: (
            <div>
              <span>
                <Btn attrBtn={{ style: style, color: 'danger', className: 'btn btn-xs', type: 'button', onClick:()=>handleDelete(type.id) }}>Delete</Btn>
              </span>
            </div>
          ),
        }))
      );
    }
  }, [allOfferList, setData]);

  return null;
};

export const  tableColumns = [
  {
    name: "Image",
    selector: (row) => row.image,
    sortable: true,
    center: true,
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    center: false,
  },
  
  {
    name: "Regular Price",
    selector: (row) => row.regularPrice,
    sortable: true,
    center: false,
  },
  {
    name: "Sale Price",
    selector: (row) => row.salePrice,
    sortable: true,
    center: false,
  },
  {
    name: "Status",
    selector: (row) => row.offerStatus,
    sortable: true,
    center: false,
  },
  {
    name: 'Action',
    selector: (row) => row.action,
    sortable: true,
    center: true,
    minWidth: '160px',
    maxWidth: '160px',
  }
  
];

